import { Badge } from '@finalytic/components';
import { useQuery, useTeamId } from '@finalytic/data';
import {
  CalendarEventIcon,
  CircleDollarIcon,
  FolderOpenIcon,
  HashtagIcon,
  Icon,
  NoteTextIcon,
} from '@finalytic/icons';
import type { MRT_ColumnDef } from '@finalytic/table';
import { Drawer } from '@finalytic/ui';
import { type Maybe, day, formatCurrency, sum } from '@finalytic/utils';
import {
  Avatar,
  Box,
  Center,
  Group,
  LoadingOverlay,
  Stack,
  Title,
  rem,
} from '@mantine/core';
import { Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { getListingName } from '@vrplatform/ui-common';
import { useMemo, useState } from 'react';
import { DepositEllipsisMenuItems } from '../../views/deposits/list/DepositEllipsisMenuItems';
import { DepositEllipsisMenuModals } from '../../views/deposits/list/DepositEllipsisMenuModals';
import { getDeposit } from '../../views/deposits/list/useDepositsTableQuery';
import { useListingDetailDrawer } from '../../views/listings/drawer';
import { DepositLineAmountHoverCard } from '../../views/reconciliation/_components';
import {
  DrawerCollapsableTable,
  DrawerHeader,
  DrawerInfoCard,
} from '../_components';
import { useReservationDetailDrawer } from '../reservation-drawers';
import { useDepositDetailDrawer } from './useDepositDetailDrawer';

function useDepositQuery(id: Maybe<string>) {
  const [teamId] = useTeamId();

  const query = useQuery(
    (q, args) => {
      if (!args.id) return null;

      return (
        q
          .transactions({
            where: {
              id: { _eq: args.id },
              type: { _eq: 'deposit' },
            },
            limit: 1,
          })
          .map((deposit) => getDeposit(deposit, { includeDetails: true }))[0] ||
        null
      );
    },
    {
      skip: !id,
      queryKey: 'Deposits',
      variables: {
        id,
        teamId,
      },
    }
  );

  const [debounced] = useDebouncedValue(query.data, 500);

  return { ...query, data: query.data || debounced };
}

type Deposit = ReturnType<typeof getDeposit>;

export const DepositDetailDrawer = () => {
  const { opened, close, depositId } = useDepositDetailDrawer();
  const { isLoading, data: deposit } = useDepositQuery(depositId);
  const [openedModal, setOpenedModal] = useState<'delete' | 'edit' | null>(
    null
  );

  const closeModal = () => setOpenedModal(null);

  return (
    <>
      {depositId && (
        <DepositEllipsisMenuModals
          deposit={deposit}
          deleteModal={{
            closeModal,
            opened: openedModal === 'delete',
          }}
        />
      )}

      <Drawer opened={opened} onClose={close} size={550}>
        <DrawerHeader
          closeDrawer={close}
          loading={isLoading}
          type="Deposit"
          title={
            deposit && (
              <Group wrap="nowrap" gap="xs" mt={rem(5)}>
                <Center w={20}>
                  {deposit.connection.logo ? (
                    <Avatar
                      src={deposit.connection.logo}
                      size="sm"
                      styles={{
                        placeholder: { visibility: 'hidden' },
                      }}
                      sx={(theme) => ({
                        border: `1px solid ${theme.colors.gray[2]}`,
                      })}
                    />
                  ) : (
                    <Icon icon="UserIcon" size={20} />
                  )}
                </Center>
                <Box>
                  <Title order={4} fw={500} component="p" m={0}>
                    {deposit.connection.name || 'Manual deposit'}
                  </Title>
                </Box>
              </Group>
            )
          }
          menuItems={
            deposit && (
              <DepositEllipsisMenuItems
                deposit={deposit}
                openDeleteModal={() => setOpenedModal('delete')}
              />
            )
          }
        />
        {!deposit && !isLoading ? (
          'No deposit details found'
        ) : (
          <Content deposit={deposit} isLoading={isLoading} />
        )}
      </Drawer>
    </>
  );
};

const Content = ({
  deposit,
  isLoading,
}: { deposit: Maybe<Deposit>; isLoading: boolean }) => {
  if (!deposit) return null;

  const paymentLines = deposit.reservationLines.filter(
    (x) => x.accountAssignmentType === 'accountsReceivable' && x.centTotal >= 0
  );
  const refundLines = deposit.reservationLines.filter(
    (x) => x.accountAssignmentType === 'accountsReceivable' && x.centTotal < 0
  );
  const reserveLines = deposit.reservationLines.filter(
    (x) => x.accountAssignmentType === 'deposit_reserve'
  );

  return (
    <Stack
      gap={'md'}
      mb="md"
      sx={{
        position: 'relative',
      }}
    >
      <DrawerInfoCard
        rows={[
          {
            icon: CircleDollarIcon,
            title: 'Amount',
            text: formatCurrency(
              (deposit.centTotal || 0) / 100,
              deposit.currency
            ),
          },
          {
            icon: CalendarEventIcon,
            title: 'Date',
            text: day(deposit.date).format('MMM D, YYYY'),
          },
          {
            icon: FolderOpenIcon,
            title: 'Bank/CC account',
            text: deposit.bankAccount.title,
          },
          {
            icon: HashtagIcon,
            title: 'Payout ID',
            text: deposit.uniqueRef,
          },
          {
            icon: NoteTextIcon,
            title: 'Description',
            text: deposit.description,
          },
        ]}
      />

      {!!deposit.bankRecords.length && (
        <BankRecords
          rowData={deposit.bankRecords}
          currency={deposit.currency}
        />
      )}

      <Lines
        title="Payments"
        type="reservation"
        lines={paymentLines}
        currency={deposit.currency}
      />

      <Lines
        title="Refunds"
        type="reservation"
        lines={refundLines}
        currency={deposit.currency}
      />

      <Lines
        title="Reserves"
        type="reservation"
        lines={reserveLines}
        currency={deposit.currency}
      />

      <Lines
        type="manual"
        lines={deposit.manualLines}
        currency={deposit.currency}
        title="Non-reservation items"
      />

      <LoadingOverlay
        visible={isLoading}
        loaderProps={{
          size: 'sm',
        }}
      />
    </Stack>
  );
};

type DepositLine =
  | Deposit['reservationLines'][number]
  | Deposit['manualLines'][number];

const Lines = ({
  lines,
  currency,
  type,
  title,
}: {
  lines: DepositLine[];
  currency: string;
  type: 'reservation' | 'manual';
  title: string;
}) => {
  const { open: openReservation } = useReservationDetailDrawer();
  const { open: openListing } = useListingDetailDrawer();

  const total = useMemo(
    () => formatCurrency(sum(lines, (x) => x.centTotal || 0) / 100, currency),
    [currency, ...lines.map((line) => line.centTotal || 0)]
  );

  const { data: rowData = [], isLoading: loading } = useQuery(
    (q, args) => {
      return q
        .transactionLines({
          where: {
            id: { _in: args.ids },
          },
          order_by: [{ createdAt: 'desc' }],
        })
        .map((line) => ({
          id: line.id,
          description: line.description,
          listing: {
            id: line.listingId,
            name: getListingName(line.listing),
          },
          reservation: {
            id: line.reservationId,
            guestName: line.reservation?.guestName,
            confirmationCode: line.reservation?.confirmationCode,
          },
          assignment: line.accountAssignmentType,
        }));
    },
    {
      variables: {
        ids: lines.map((x) => x.id),
      },
      queryKey: ['deposits'],
    }
  );

  type Row = (typeof rowData)[number];

  const columns = useMemo<MRT_ColumnDef<Row>[]>(
    () => [
      {
        header: 'Description',
        accessorKey: 'description',
        Cell: ({ row }) => {
          if (type === 'manual')
            return (
              <Box>
                <Text component="span" display="block" size="sm">
                  {row.original.listing.name}
                </Text>
                <Text component="span" display="block" size="xs" c="gray">
                  {row.original.description}
                </Text>
              </Box>
            );

          return (
            <Box>
              <Text component="span" display="block" size="sm">
                {row.original.reservation.guestName}
              </Text>
              <Text component="span" display="block" size="xs" c="gray">
                {row.original.reservation.confirmationCode}
              </Text>
            </Box>
          );
        },
      },
      {
        header: 'Type',
        accessorKey: 'type',
        mantineTableBodyCellProps: {
          align: 'right',
        },
        size: 100,
        Cell: ({ row }) => {
          const line = lines.find((x) => x.id === row.original.id);
          if (!line) return null;

          if (line.accountAssignmentType === 'accountsReceivable') {
            if (line.centTotal >= 0)
              return <Badge color={'lime'}>Payment</Badge>;
            else return <Badge color={'green'}>Refund</Badge>;
          }

          if (line.accountAssignmentType === 'deposit_reserve')
            return <Badge color={'yellow'}>Reserve</Badge>;

          return null;
        },
      },
      {
        header: 'Split',
        accessorKey: 'centTotal',
        mantineTableBodyCellProps: {
          align: 'right',
        },
        size: 100,
        Cell: ({ row }) => {
          const line = lines.find((x) => x.id === row.original.id);
          if (!line) return null;

          return <DepositLineAmountHoverCard {...line} currency={currency} />;
        },
      },
    ],
    [type]
  );

  if (!lines.length) return null;

  return (
    <DrawerCollapsableTable
      title={title}
      onRowClick={{
        handler: (row) => {
          if (row.original.reservation.id)
            return openReservation(
              row.original.reservation.id,
              'overview',
              'push'
            );

          if (row.original.listing.id)
            return openListing(row.original.listing.id, 'overview', 'push');
        },
        disabled: (row) =>
          !row.original.reservation?.id && !row.original.listing?.id,
      }}
      rightSection={
        <Text fw={500} size="sm">
          {total}
        </Text>
      }
      rowData={rowData}
      columns={columns}
      loading={loading}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" c="gray">
            No lines available
          </Text>
        </Center>
      )}
    />
  );
};

const BankRecords = ({
  rowData = [],
  currency,
}: {
  rowData: Deposit['bankRecords'];
  currency: string;
}) => {
  const total = sum(rowData, (x) => x.centTotal || 0) / 100;

  const columns = useMemo<
    MRT_ColumnDef<NonNullable<Deposit['bankRecords']>[0]>[]
  >(
    () => [
      {
        header: 'Description',
        accessorKey: 'description',
        Cell: ({ row }) => {
          const data = row.original;
          return (
            <Box>
              <Text size="sm">{data.description}</Text>
              {data.bankAccount && (
                <Text size="xs" c="gray">
                  {data.bankAccount}
                </Text>
              )}
            </Box>
          );
        },
      },
      {
        header: 'amount',
        accessorKey: 'amount',
        maxSize: 80,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          const amount = formatCurrency(
            (row.original.centTotal || 0) / 100,
            currency
          );
          return (
            <Box>
              <Text size="sm" ta="right">
                {amount}
              </Text>
              {row.original.date && (
                <Text size="xs" c="gray" ta="right">
                  {day(row.original.date).format('MMM DD, YYYY')}
                </Text>
              )}
            </Box>
          );
        },
      },
    ],
    [currency]
  );

  return (
    <DrawerCollapsableTable
      title={'Reconciled bank records'}
      rightSection={
        <Text size="sm" fw={500}>
          {formatCurrency(total, currency)}
        </Text>
      }
      rowData={rowData}
      columns={columns}
      defaultOpened
      emptyRowsFallback={'No reconciled bank records found'}
    />
  );
};
