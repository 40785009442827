import { RenameModal } from '@finalytic/components';
import { useApiMutation, useMutation } from '@finalytic/data';
import { ConfirmModal, ConfirmResultType } from '@finalytic/data-ui';
import { Icon } from '@finalytic/icons';
import {
  DeleteModal,
  showErrorNotification,
  showLoadingNotification,
  updateErrorNotification,
  updateSuccessNotification,
} from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import { connectionHiddenStatusDefault } from '@vrplatform/ui-common';
import { ConnectionRow } from './useConnectionTableQuery';

export const ConnectionEllipsisMenuModals = ({
  data,
  deleteModal,
  renameModal,
  updateModal,
  refresh,
}: {
  data: Maybe<ConnectionRow>;
  deleteModal: {
    opened: boolean;
    close: () => void;
  };
  updateModal: {
    opened: boolean;
    close: () => void;
  };
  renameModal: {
    opened: boolean;
    close: () => void;
  };
  refresh: () => void;
}) => {
  const id = data?.id;

  const notificationId = 'delete-connection';

  const { mutateAsync: deleteConnection } = useApiMutation(
    'delete',
    '/connections/{id}'
  );

  const connectionStatus = data?.connectionStatus || 'active';
  const connectionName = data?.name;
  const isArchived = connectionHiddenStatusDefault.includes(
    connectionStatus.toLowerCase()
  );

  const handleConnectionDeletion = async (): Promise<ConfirmResultType> => {
    if (!id) return { isSuccess: false, message: 'Connection ID is missing.' };

    try {
      showLoadingNotification({
        id: notificationId,
      });

      const res = await deleteConnection({
        params: {
          path: {
            id,
          },
          query: {
            onLocked: 'archive',
          },
        },
      });

      if (res.status === 'archived') {
        updateSuccessNotification({
          id: notificationId,
          title: 'Connection was archived.',
          message:
            'The connection could not be deleted and not was archived instead. This is most likely due to data in the system that is still connected to this connection.',
        });
      } else {
        updateSuccessNotification({
          id: notificationId,
          title: 'Success!',
          message: 'Connection deleted successfully.',
        });
      }

      if (refresh) refresh();
      return { isSuccess: true, autoCloseOnSuccess: true };
    } catch (error: any) {
      const message = error?.message || 'Failed to delete connection.';

      updateErrorNotification({
        id: notificationId,
        title: 'Failed to delete connection',
        message,
      });

      return {
        isSuccess: false,
        message,
      };
    }
  };

  const { mutate: updateConnectionStatus } = useMutation(
    (
      q,
      args: { id: string; status: 'active' | 'archived'; tenantId: string }
    ) => {
      const connection = q.updateConnectionById({
        pk_columns: { id: args.id },
        _set: {
          status: args.status,
        },
      });

      if (args.status === 'archived') {
        q.updateAutomations({
          where: {
            tenantId: { _eq: args.tenantId },
            _or: [
              {
                leftConnectionId: { _eq: args.id },
              },
              {
                rightConnectionId: { _eq: args.id },
              },
            ],
          },
          _set: {
            status: 'inactive',
          },
        })?.affected_rows || 0;
      }

      return {
        ok: !!connection?.id,
      };
    },
    {
      successMessage: {
        id: `update-connection-${data?.id || ''}`,
        title: 'Success!',
        message: 'Successfully updated connection.',
      },
      invalidateQueryKeys: ['connections', 'scheduledEvents'],
    }
  );

  const handleConnectionArchive = async (): Promise<ConfirmResultType> => {
    if (!id) return { isSuccess: false, message: 'Connection ID is missing.' };
    if (!data.tenant?.id)
      return { isSuccess: false, message: 'Tenant ID is missing.' };

    try {
      const res = await updateConnectionStatus({
        args: {
          id,
          status: isArchived ? 'active' : 'archived',
          tenantId: data?.tenant.id,
        },
      });
      if (!res?.ok) throw new Error('Connection update failed.');
      if (refresh) refresh();
      return { isSuccess: true, autoCloseOnSuccess: true };
    } catch (error: any) {
      console.error(error);
      return {
        isSuccess: false,
        message: error?.message || 'Failed to update connection.',
      };
    }
  };

  const { mutate: updateConnectionTitle } = useMutation(
    (q, args: { id: string; title: string }) => {
      const connection = q.updateConnectionById({
        pk_columns: { id: args.id },
        _set: {
          name: args.title,
        },
      });

      return {
        ok: !!connection?.id,
      };
    },
    {
      successMessage: {
        id: `update-connection-${data?.id || ''}`,
        title: 'Success!',
        message: 'Successfully updated connection.',
      },
      invalidateQueryKeys: ['connections', 'scheduledEvents'],
    }
  );

  return (
    <>
      <DeleteModal
        opened={deleteModal.opened}
        onClose={deleteModal.close}
        onSubmit={handleConnectionDeletion}
        title={`Are you sure you want to delete this connection: ${connectionName}?`}
        subtitle="All connected data will be lost."
      />
      <ConfirmModal
        opened={updateModal.opened}
        onClose={updateModal.close}
        onSubmit={handleConnectionArchive}
        title={isArchived ? 'Enable connection' : 'Archive connection'}
        withCloseButton
        onSuccess={refresh}
        subtitle={
          isArchived
            ? `Would you like to enable ${connectionName || 'this connection'}?`
            : `Are you sure you want to archive ${
                connectionName || 'this connection'
              }? This will disable all automations connected to this connection.`
        }
        submitButtonTitle={isArchived ? 'Enable' : 'Archive'}
        icon={
          <Icon icon={isArchived ? 'RefreshCwIcon' : 'ArchiveIcon'} size={48} />
        }
        autoCloseOnSuccess
      />
      <RenameModal
        opened={renameModal.opened}
        onClose={renameModal.close}
        title="Edit Connection Name"
        inputTitle="Name"
        inputPlaceholder="Enter your connection name"
        defaultValue={connectionName}
        onSubmit={async (value) => {
          if (!id)
            return showErrorNotification({
              color: 'yellow',
              message: 'Connection ID is missing.',
              title: 'Missing input',
            });

          try {
            await updateConnectionTitle({
              args: {
                id,
                title: value,
              },
            });
            if (refresh) refresh();
            renameModal.close();
          } catch (error) {
            console.log(error);
          }
        }}
      />
    </>
  );
};
