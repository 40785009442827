import { RenameModal, Select } from '@finalytic/components';
import { useMutation, useQuery, useTeamId } from '@finalytic/data';
import { ChevronIcon, MinusCircleIcon, PlusIcon } from '@finalytic/icons';
import { LoadingIndicator, SelectItem } from '@finalytic/ui';
import { hasValue } from '@finalytic/utils';
import { Group, Text, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

export const ListingCollectionSelect = ({
  collection,
  listingId,
}: {
  listingId: string;
  collection: {
    id: string | null;
    name: string;
  };
}) => {
  const [teamId] = useTeamId();
  const [opened, handlers] = useDisclosure(false);
  const { data, isLoading: loadingQuery } = useQuery(
    (q, args) => {
      return q
        .listingCollections({
          where: {
            tenantId: {
              _eq: args.teamId,
            },
          },
        })
        ?.map<SelectItem>((listingGroup) => ({
          label: listingGroup.name || '',
          value: listingGroup.id,
        }));
    },
    {
      queryKey: 'listingCollections',
      variables: {
        teamId,
      },
    }
  );

  const { mutate, loading: loadingMutation } = useMutation(
    (q, args: { listingId: string; collectionId: string | null }) => {
      return q.updateListing({
        pk_columns: {
          id: args.listingId,
        },
        _set: {
          collectionId: args.collectionId,
        },
      })?.id;
    },
    {
      invalidateQueryKeys: ['listings'],
    }
  );

  const { mutate: insertGroup } = useMutation(
    (
      q,
      args: {
        name: string;
        tenantId: string;
        listingId: string;
      }
    ) => {
      return (
        q.insertListingCollection({
          object: {
            name: args.name,
            tenantId: args.tenantId,
            listings: {
              data: [
                {
                  id: args.listingId,
                  tenantId: args.tenantId,
                },
              ],
              on_conflict: {
                constraint: 'listing_pkey',
                update_columns: ['collectionId'],
              },
            },
          },
        })?.id || null
      );
    },
    {
      invalidateQueryKeys: ['listings', 'listingCollections'],
    }
  );

  const updateListingGroup = async (collectionId: string | null) =>
    mutate({
      args: {
        collectionId,
        listingId,
      },
    });

  return (
    <>
      <Select
        data={{
          options: data || [],
          loading: loadingQuery,
        }}
        type="single"
        setValue={(value) => updateListingGroup(value?.value || null)}
        value={
          collection?.id
            ? { label: collection.name, value: collection.id }
            : null
        }
        inputProps={{
          loadingMutation,
          loadingQuery,
        }}
        dropdownProps={{
          withinPortal: true,
          position: 'bottom-start',
          zIndex: 200,
        }}
        customBottomActions={[
          {
            label: 'Create new listing group',
            description: 'Share your mappings across listings',
            icon: <PlusIcon size={16} />,
            onSubmit: handlers.open,
            id: 'add-listing-group',
          },
          collection?.id
            ? {
                label: 'Remove from listing group',
                icon: <MinusCircleIcon size={16} />,
                onSubmit: () => updateListingGroup(null),
                id: 'remove-listing-group',
              }
            : null,
        ].filter(hasValue)}
      >
        {({ value, type, opened, loadingMutation }) => {
          const { primaryColor, colors, radius, spacing } = useMantineTheme();

          if (type !== 'single') return <div />;

          return (
            <Group
              wrap="nowrap"
              gap={8}
              display="inline-flex"
              sx={{
                cursor: 'pointer',
                border: '1px solid',
                borderColor: opened ? colors[primaryColor][6] : 'transparent',
                borderRadius: radius.sm,
                boxShadow: opened
                  ? `0px 0px 0px 2px ${colors[primaryColor][4]}40`
                  : undefined,
                color: colors[primaryColor][7],
                padding: `2px ${spacing.sm}`,
              }}
            >
              <Text lineClamp={1}>{value?.label || 'Select group'}</Text>
              {loadingMutation ? (
                <LoadingIndicator size={'0.75rem'} />
              ) : (
                <ChevronIcon color={colors[primaryColor][7]} size={16} />
              )}
            </Group>
          );
        }}
      </Select>
      <RenameModal
        inputTitle="Listing group"
        onClose={handlers.close}
        onSubmit={async (name) => {
          return await insertGroup({
            args: {
              name: name.trim(),
              tenantId: teamId,
              listingId,
            },
          }).then(() => {
            handlers.close();
          });
        }}
        opened={opened}
        title="Add listing group"
        inputPlaceholder="Listing group"
      />
    </>
  );
};
