import { tenant_bool_exp } from '@finalytic/graphql';
import { ensure, isUUID } from '@finalytic/utils';
import { z } from 'zod';
import { VRP_TENANT_ID, XIMPLIFI_TENANT_ID } from '../consts';
import { Dashboard } from '../types';

const tenantsFilterInput = {
  search: z.string().nullish(),
  tenantId: z.string().nullish(),
  billingPartnerId: z.string().nullish(),
  billingSubscriptionStatus: z
    .enum([
      'active',
      'pending',
      'paused',
      'cancelled',
      'draft',
      'voided',
      'errored',
      'missing',
      'viaPartner',
    ])
    .nullish(),
  status: z.enum(['active', 'inactive', 'onboarding']).nullish(),
};

const tenantsFilter = z.object(tenantsFilterInput);

type TenantsFilterParams = z.infer<typeof tenantsFilter>;

type BaseWhere = {
  partnerId: string | undefined;
  dashboard: Dashboard;
};

export function whereTenants({
  search: s,
  partnerId,
  status,
  dashboard,
  billingPartnerId,
  billingSubscriptionStatus,
  tenantId,
}: BaseWhere & TenantsFilterParams) {
  const isOverviewDashboard = dashboard === 'overview';
  const search = s?.trim();
  const searchQuery = search
    ? ensure<tenant_bool_exp[]>([
        {
          name: { _ilike: `%${search}%` },
        },
        {
          companyName: { _ilike: `%${search}%` },
        },
      ])
    : undefined;

  const billingPartnerFilter = (
    _billingPartnerId = billingPartnerId
  ): tenant_bool_exp[] | [] => {
    if (!_billingPartnerId || !isUUID(_billingPartnerId)) return [];

    return [
      {
        _or: [
          {
            billingPartnerId: { _eq: _billingPartnerId },
          },
          {
            partnerId: { _eq: _billingPartnerId },
            billingPartnerId: { _is_null: true },
          },
        ],
      },
    ];
  };

  return ensure<tenant_bool_exp>({
    id: tenantId ? { _eq: tenantId } : undefined,
    partnerId: isOverviewDashboard ? undefined : { _eq: partnerId },
    status: status !== 'onboarding' ? { _eq: status } : undefined,
    billingSubscriptionStatus: billingSubscriptionStatus
      ? billingSubscriptionStatus === 'missing' ||
        billingSubscriptionStatus === 'viaPartner'
        ? { _is_null: true }
        : { _eq: billingSubscriptionStatus }
      : undefined,
    isOnboarding:
      status === 'onboarding' || status === 'active'
        ? { _eq: status === 'onboarding' }
        : undefined,
    type: { _nin: ['partner', 'admin'] },
    _or: searchQuery,
    _and: [
      ...billingPartnerFilter(),
      ...(billingSubscriptionStatus === 'viaPartner'
        ? [
            {
              _or: [
                {
                  billingPartnerId: {
                    _nin: [VRP_TENANT_ID, XIMPLIFI_TENANT_ID],
                  },
                },
                {
                  partnerId: { _nin: [VRP_TENANT_ID, XIMPLIFI_TENANT_ID] },
                  billingPartnerId: { _is_null: true },
                },
              ],
            },
          ]
        : []),
      ...(billingSubscriptionStatus === 'missing'
        ? [
            {
              _or: [
                ...billingPartnerFilter(VRP_TENANT_ID),
                ...billingPartnerFilter(XIMPLIFI_TENANT_ID),
              ],
            },
          ]
        : []),
    ],
  });
}
