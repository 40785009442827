import type {
  transaction as Transaction,
  transactionLine_bool_exp,
  transactionLine_order_by,
} from '@finalytic/graphql';
import { type Maybe, ensure, hasValue } from '@finalytic/utils';
import { getTransactionLineType } from '@vrplatform/ui-common';
import type {
  DepositFormInputs,
  ReservationPaymentLine,
  ReservationReserveLine,
} from './_hooks/useDepositForm';
import {
  getDepositManualLinesCentTotal,
  getDepositReservationLinesCentTotal,
} from './_utils';

type Extras = {
  transactionId: string;
  description: Maybe<string>;
};

export const getDepositLines = (
  q: Transaction['lines'],
  where?: transactionLine_bool_exp,
  orderBy?: transactionLine_order_by[]
) => {
  const lines = q({
    order_by: orderBy || [{ createdAt: 'desc' }],
    where,
  }).map((line) => ({
    id: line.id,
    assignment: line.accountAssignmentType,
    centTotal: line.centTotal ?? 0,
    description: line.description || '',
    accountId: line.accountId,
    reservationId: (line.reservationId || null) as string | null,
    listingId: line.listingId,
    party: line.party,
    contactId: line.contactId,
    type: getTransactionLineType(line),
    transactionId: line.transactionId,
    additions: line
      .additions({ order_by: [{ updatedAt: 'desc' }] })
      .map((addition) => ({
        id: addition.id,
        centTotal: addition.centTotal,
        type: addition.type,
        accountId: addition.accountId,
        rateId: addition.rateId,
        behavior: addition.behavior,
      })),
  }));

  // 1. merge reservation payments into 1 line per reservation
  // 2. merge reservation merchantFees into 1 line per reservation
  // 3. merge reservation channelFees into 1 line per reservation

  // 4. get unique reservationIds from payments/merchantFees/channelFees and create reservation_line payments
  // 5. get resolutions

  const linesWithReservation = lines.filter((x) => x.reservationId);

  const reduceLines = (l: typeof lines) =>
    l.reduce<typeof lines>((acc, line) => {
      const reservationId = line.reservationId;
      const index = acc.findIndex((x) => x.reservationId === reservationId);
      if (index === -1) acc.push(line);
      else acc[index].centTotal = acc[index].centTotal + line.centTotal;
      return acc;
    }, []);

  const payment_lines = reduceLines(
    linesWithReservation.filter((x) => x.type === 'payment')
  );
  const merchantFee_lines = reduceLines(
    linesWithReservation.filter((x) => x.type === 'merchantFee')
  );
  const channelFee_lines = reduceLines(
    linesWithReservation.filter((x) => x.type === 'channelFee')
  );

  const uniqueReservationIds = [
    ...new Set(
      [...payment_lines, ...merchantFee_lines, ...channelFee_lines]
        .map((x) => x.reservationId)
        .filter(hasValue)
    ),
  ];

  const reservations = uniqueReservationIds.map<
    ReservationPaymentLine & Extras
  >((reservationId) => {
    const payment = payment_lines.find(
      (x) => x.reservationId === reservationId
    );
    const merchantFee = merchantFee_lines.find(
      (x) => x.reservationId === reservationId
    );
    const channelFee = channelFee_lines.find(
      (x) => x.reservationId === reservationId
    );

    return {
      id: payment?.id || merchantFee?.id || channelFee?.id,
      reservationId,
      accountAssignmentType: 'accountsReceivable',
      centTotal: payment?.centTotal ?? 0,
      merchantFeeCentTotal: Math.abs(merchantFee?.centTotal ?? 0),
      channelFeeCentTotal: Math.abs(channelFee?.centTotal ?? 0),
      description:
        payment?.description ||
        merchantFee?.description ||
        channelFee?.description,
      transactionId:
        payment?.transactionId ||
        merchantFee?.transactionId ||
        channelFee?.transactionId,
    };
  });

  const resolutions = lines
    .filter(
      (x) =>
        x.type &&
        x.reservationId &&
        ensure<(typeof x)['type'][]>(['reserve']).includes(x.type)
    )
    .map((x) => {
      const tax = x.additions.find((a) => a.type === 'tax');

      return ensure<ReservationReserveLine & Extras>({
        id: x.id,
        description: x.description,
        reservationId: x.reservationId!,
        accountAssignmentType: 'deposit_reserve',
        centTotal: x.centTotal,
        merchantFeeCentTotal: 0,
        party: x.party || 'manager',
        rateId: tax?.rateId || null,
        rateBehavior: tax?.behavior || 'included',
        transactionId: x.transactionId,
      });
    });

  const reservationLines = [...reservations, ...resolutions];

  const manualMerchantFeeLines = lines.filter(
    (x) => !x.reservationId && x.assignment === 'deposit_merchantFee'
  );

  const manualLines: DepositFormInputs['manualLines'] = lines
    .filter((x) => !x.reservationId && x.assignment !== 'deposit_merchantFee')
    .map((x) => {
      // const tax = x.additions.find((a) => a.rateId);

      const merchantIndex = manualMerchantFeeLines.findIndex(
        (a) => x.contactId === a.contactId && x.party === a.party
      );

      const merchantFeeLine = manualMerchantFeeLines.splice(
        merchantIndex,
        1
      )[0];

      return ensure<DepositFormInputs['manualLines'][0] & Extras>({
        id: x.id,
        description: x.description,
        listingId: x.listingId,
        accountId: x.accountId,
        party: x.party || 'manager',
        centTotal: x.centTotal,
        merchantFeeCentTotal: Math.abs(merchantFeeLine?.centTotal ?? 0),
        contactId: x.contactId,
        transactionId: x.transactionId,
        accountAssignmentType: (x.assignment || null) as any,
        reservationId: x.reservationId,
        // rateBehavior: tax?.behavior || 'included',
        // rateId: tax?.rateId || null,
      });
    });

  const manualLineCentTotal = getDepositManualLinesCentTotal(manualLines);

  const reservationLinesCentTotal =
    getDepositReservationLinesCentTotal(reservationLines);

  return {
    totals: {
      reservationLinesCentTotal,
      manualLineCentTotal,
      centTotal: manualLineCentTotal + reservationLinesCentTotal,
    },
    lines: {
      manualLines,
      reservationLines,
    },
  };
};
