import { useLocalStorage, useMediaQuery } from '@mantine/hooks';

export function useNavbarExpanded() {
  const [isDesktopExpanded, setIsDesktopExpanded] = useLocalStorage({
    key: 'navbar-expanded',
    defaultValue: true,
  });

  const mobileBreakpoint = 755;

  const isMobile = useMediaQuery(`(max-width: ${755}px)`);

  return {
    mobileBreakpoint,
    isDesktopExpanded: isMobile ? true : isDesktopExpanded,
    setIsDesktopExpanded,
    isMobile,
  };
}
