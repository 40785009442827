import { useQuery, useTeamRole } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import {
  EllipsisMenuCopyItem,
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
  StringParam,
  useQueryParamsSet,
} from '@finalytic/ui';
import { Maybe, toTitleCase } from '@finalytic/utils';
import { Box, Tooltip } from '@mantine/core';
import {
  connectionHiddenStatusDefault,
  deleteCheckConnection,
} from '@vrplatform/ui-common';
import { useExtractModal } from '../../../modals';
import { ConnectionRow } from './useConnectionTableQuery';

export const ConnectionEllipsisMenuItems = ({
  data,
  openRenameModal,
  openDeleteModal,
  openUpdateModal,
}: {
  data: Maybe<ConnectionRow>;
  openRenameModal: () => void;
  openDeleteModal: () => void;
  openUpdateModal: () => void;
}) => {
  const { isPartnerAdmin } = useTeamRole();

  const id = data?.id;

  const setSearchParams = useQueryParamsSet({
    connectionId: StringParam,
    connectionDetailId: StringParam,
  });

  const { open: openExtractModal } = useExtractModal();

  const reconnect = () => setSearchParams({ connectionId: id });
  const connectionStatus = data?.connectionStatus || 'active';
  const isArchived = connectionHiddenStatusDefault.includes(
    connectionStatus.toLowerCase()
  );

  if (!id) return null;

  return (
    <>
      <EllipsisMenuItem
        customIcon={<Icon icon="Edit3Icon" size={16} />}
        onClick={openRenameModal}
      >
        Rename
      </EllipsisMenuItem>
      <EllipsisMenuItem
        customIcon={<Icon icon="LightningIcon" size={16} />}
        onClick={reconnect}
      >
        Reconnect
      </EllipsisMenuItem>
      <EllipsisMenuItem
        customIcon={<Icon icon="RefreshCwIcon" size={16} />}
        onClick={() => {
          openExtractModal({
            connectionIds: [id],
            extractType: undefined,
          });
        }}
      >
        Fetch
      </EllipsisMenuItem>
      <EllipsisMenuDivider />
      <EllipsisMenuItem
        onClick={openUpdateModal}
        customIcon={
          <Icon icon={isArchived ? 'RefreshCwIcon' : 'ArchiveIcon'} size={16} />
        }
      >
        {isArchived ? 'Enable' : 'Archive'}
      </EllipsisMenuItem>
      <DeleteEllipsisItem connectionId={id} openDeleteModal={openDeleteModal} />

      {isPartnerAdmin && <EllipsisMenuCopyItem value={id} />}
    </>
  );
};

const DeleteEllipsisItem = ({
  connectionId,
  openDeleteModal,
}: { connectionId: string; openDeleteModal: () => void }) => {
  const { data: removeCheckData, isLoading: loading } = useQuery(
    (q, { connectionId }) => {
      const res = deleteCheckConnection(q, { connectionId }) || null;

      return res;
    },
    {
      variables: { connectionId },
      skip: !connectionId,
    }
  );

  const canDelete = removeCheckData?.canDelete;
  const canBeRemovedProps = Object.entries(removeCheckData || {}).filter(
    ([, value]) => typeof value === 'number' && value > 0
  ) as [string, number][];

  return (
    <Tooltip
      label={`Please remove connected ${toTitleCase(
        canBeRemovedProps?.[0]?.[0]
      )?.toLowerCase()} first.`}
      disabled={canDelete || canDelete === undefined}
    >
      <Box>
        <EllipsisMenuDangerItem
          disabled={!canDelete}
          loading={loading}
          onClick={openDeleteModal}
          data-testid="connection-delete"
        >
          Delete
        </EllipsisMenuDangerItem>
      </Box>
    </Tooltip>
  );
};
