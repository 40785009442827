import { useDashboard, useEnabledFeatures } from '@finalytic/data';
import {
  InfiniteTable,
  MRT_ColumnDef,
  MRT_SortingState,
} from '@finalytic/table';
import { StringParam, useQueryParamSet } from '@finalytic/ui';
import { Maybe, ensure, formatCurrency, hasValue, utc } from '@finalytic/utils';
import {
  Avatar,
  Box,
  Group,
  Text,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import {
  PaymentsReservationsActionIcons,
  ReservationStatusBadge,
} from '../../components';
import { TableFilterType, useGenericTableStore } from '../../stores';
import { ReservationFilter, useReservationFilter } from './ReservationFilter';
import {
  ReservationRow,
  useReservationTableQuery,
} from './useReservationTableQuery';

type Props = {
  onRowClickType: 'detail' | 'select';
  filterByAutomationId: string | undefined;
  filterType: TableFilterType;
  hideCancellations: boolean;
  hideTotal: boolean;
};

export const ReservationsBaseTable = ({
  onRowClickType,
  filterByAutomationId,
  filterType,
  hideCancellations,
  hideTotal,
}: Props) => {
  const [dashboard] = useDashboard();
  const { GL, GL_WITH_LEGACY_AUTOMATIONS } = useEnabledFeatures();
  const isOwnerPortal = dashboard === 'owner';

  const setReservation = useQueryParamSet('reservation', StringParam);

  const { reset } = useReservationFilter();

  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: 'checkIn', desc: true },
  ]);

  const rowSelection = useGenericTableStore((st) => st.selected);
  const allPagesSelected = useGenericTableStore((st) => st.allPagesSelected);
  const setRowSelection = useGenericTableStore((st) => st.setSelected);
  const setAllPagesSelected = useGenericTableStore(
    (st) => st.setAllPagesSelected
  );

  const setFilterType = useGenericTableStore((st) => st.setFilterType);
  const currentFilterType = useGenericTableStore((st) => st.filterType);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (currentFilterType !== filterType) setFilterType(filterType);
  }, [currentFilterType, filterType]);

  const queryData = useReservationTableQuery({
    sorting,
    filterByAutomationId,
    hideCancellations,
  });

  const columns = useMemo(
    () =>
      ensure<(MRT_ColumnDef<ReservationRow> | undefined)[]>([
        {
          accessorKey: 'connection',
          enableSorting: false,
          header: 'Connection',
          maxSize: 120,
          Cell: ({ row }) => {
            const data = row.original;

            return (
              <TextCell
                firstLine={data.connection?.name}
                secondLine={data.listingConnection?.listing?.name}
                icon={
                  data.connection.app?.iconRound && (
                    <Avatar
                      src={data.connection.app?.iconRound}
                      size="sm"
                      sx={(theme) => ({
                        border: `1px solid ${theme.colors.gray[2]}`,
                      })}
                    />
                  )
                }
              />
            );
          },
        },
        {
          accessorKey: 'checkIn',
          header: 'Dates',
          maxSize: 100,
          enableSorting: true,
          Cell: ({ row }) => {
            const data = row.original;

            const line1 = `${utc(data?.checkIn).format('DD. MMM')} -
          ${utc(data?.checkOut).format('DD. MMM. YYYY')}`;
            const line2 = `${data?.nights || 0} nights${
              data?.bookedAt
                ? `, on ${utc(data?.bookedAt).format('DD. MMM')}`
                : ''
            }`;

            return <TextCell firstLine={line1} secondLine={line2} />;
          },
        },

        {
          accessorKey: 'guestName',
          header: 'Guest name',
          maxSize: 100,
          Cell: ({ row }) => {
            const data = row.original;
            return (
              <TextCell
                firstLine={data.guestName}
                secondLine={data.confirmationCode?.replace(
                  /(.{22})..+/,
                  '$1...'
                )}
              />
            );
          },
        },

        isOwnerPortal || (GL && !GL_WITH_LEGACY_AUTOMATIONS)
          ? undefined
          : {
              header: 'Automations',
              accessorKey: 'sourceId',
              maxSize: 70,
              Cell: ({ row }) => {
                const data = row.original;

                if (!data.sourceId || !data.id) return null;

                return (
                  <PaymentsReservationsActionIcons
                    id={data.id}
                    sourceId={data.sourceId}
                  />
                );
              },
            },

        {
          accessorKey: 'status',
          header: 'Status',
          maxSize: 50,
          mantineTableBodyCellProps: { align: 'center' },
          mantineTableHeadCellProps: {
            align: 'center',
            px: 0,
            sx: (theme) => ({
              '.mrt-table-head-cell-labels': {
                paddingLeft: 0,
                fontWeight: 400,
                color: theme.colors.gray[7],
              },
            }),
          },
          Cell: ({ row }) => {
            const data = row.original;
            return (
              <ReservationStatusBadge
                status={data.status}
                cancelledAt={data.cancelledAt}
              />
            );
          },
        },
        hideTotal
          ? undefined
          : {
              accessorKey: 'total',
              maxSize: 50,
              header: 'Total',
              mantineTableHeadCellProps: {
                align: 'right',
              },
              mantineTableBodyCellProps: {
                align: 'right',
              },
              Cell: ({ row }) => {
                const data = row.original;
                const line1 = formatCurrency(
                  (data.total || 0) / 100,
                  data?.currency as any
                );
                return (
                  <TextCell
                    firstLine={line1}
                    secondLine={
                      data.unpaid
                        ? `${formatCurrency(
                            (data.unpaid || 0) / 100,
                            data?.currency as any
                          )} unpaid`
                        : undefined
                    }
                  />
                );
              },
            },
      ]).filter(hasValue),
    [isOwnerPortal, GL, GL_WITH_LEGACY_AUTOMATIONS, hideTotal]
  );

  return (
    <InfiniteTable
      columns={columns}
      table={{
        key: 'reservations',
        onRowClick: isOwnerPortal
          ? undefined
          : {
              handler: (row) => {
                if (onRowClickType === 'detail') {
                  return setReservation(row.original.id);
                }

                if (onRowClickType === 'select') {
                  const canSelect = row.getCanSelect();

                  if (canSelect) {
                    row.toggleSelected();
                  }
                }
              },
            },
      }}
      queryData={queryData}
      sorting={{
        sorting,
        setSorting,
      }}
      selecting={
        isOwnerPortal
          ? undefined
          : {
              rowSelection: {
                rows: rowSelection,
                allPagesSelected,
              },
              setAllPagesSelected,
              setRowSelection,
            }
      }
      resetFilter={reset}
    >
      <ReservationFilter />
    </InfiniteTable>
  );
};

const TextCell = ({
  firstLine,
  secondLine,
  icon,
}: {
  firstLine: Maybe<string>;
  secondLine: Maybe<string>;
  icon?: React.ReactNode;
}) => {
  const { colors } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  return (
    <Group wrap="nowrap" gap="sm">
      {icon}
      <Box>
        {firstLine && (
          <Text size="sm" component="p" m={0}>
            {firstLine}
          </Text>
        )}
        {secondLine && (
          <Text
            color={colorScheme === 'dark' ? colors.gray[6] : 'gray'}
            size="xs"
            component="p"
            m={0}
          >
            {secondLine}
          </Text>
        )}
      </Box>
    </Group>
  );
};
