import { InputSelect } from '@finalytic/components';
import { gqlV2, useQuery } from '@finalytic/data';
import { currency_enum } from '@finalytic/graphql';
import { SelectItem } from '@finalytic/ui';
import { sortBy, toTitleCase } from '@finalytic/utils';
import { Box, InputWrapper } from '@mantine/core';
import { currencies } from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useExpenseForm } from '../useExpenseForm';

export const ExpenseCurrencyInput = () => {
  const methods = useExpenseForm();

  const { data, isLoading: loading } = useQuery(
    (q, args) => {
      const currencies = args.availableCurrencies;

      const c = q
        .currency({
          order_by: [{ name: 'asc_nulls_last' }],
        })
        .map<SelectItem<gqlV2.currency_enum>>((item) => ({
          label:
            currencies[
              (item.name?.toUpperCase() || '') as keyof typeof currencies
            ]?.currencyName ||
            toTitleCase(item.name) ||
            '',
          value: item.name as currency_enum,
        }));

      return sortBy(c, 'label', 'asc');
    },
    {
      variables: {
        availableCurrencies: currencies,
      },
    }
  );

  const { options, pinned } = useMemo(() => {
    const opts = data || [];

    const pinned = ['usd', 'eur', 'gbp'];

    return {
      options: opts.filter((o) => !pinned.includes(o.value)),
      pinned: opts.filter((o) => pinned.includes(o.value)),
    };
  }, [data]);

  return (
    <Box>
      <Controller
        control={methods.control}
        name="currency"
        rules={{
          required: 'Currency is required',
        }}
        defaultValue="usd"
        render={({ field, fieldState }) => {
          const value =
            [...options, ...pinned]?.find((o) => o.value === field.value) ||
            null;

          return (
            <InputWrapper required error={fieldState.error?.message}>
              <InputSelect
                type="single"
                value={value}
                setValue={(v) => v?.value && field.onChange(v?.value)}
                data={{
                  options,
                  loading,
                  sort: null,
                }}
                inputProps={{
                  error: !!fieldState.error,
                  placeholder: options[0]?.label || 'Select type',
                  loadingQuery: loading,
                }}
                dropdownProps={{
                  width: 'target',
                }}
                pinnedItems={pinned}
              />
            </InputWrapper>
          );
        }}
      />
    </Box>
  );
};
