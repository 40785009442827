import {
  activeStatus_enum,
  paidStatus_enum,
  timestamptz_comparison_exp,
  transactionType_enum,
  transaction_bool_exp,
} from '@finalytic/graphql';
import { Maybe, day } from '@finalytic/utils';

type Props = {
  type: transactionType_enum;
  tenantId: string;
  search?: Maybe<string>;
  listingIds?: Maybe<string[]>;
  date?: Maybe<string>;
  accountId?: Maybe<(string & {}) | 'non-trust'>;
  bankAccountId?: Maybe<string>;
  status?: Maybe<activeStatus_enum>;
  paidStatus?: Maybe<paidStatus_enum>;
};

export const whereTransactions = ({
  tenantId,
  date,
  listingIds,
  search,
  type,
  status,
  accountId,
  paidStatus,
  bankAccountId,
}: Props): transaction_bool_exp => {
  const getDate = (): timestamptz_comparison_exp | undefined => {
    if (!date) return undefined;

    const [start, end] = date.split('...');

    if (!end)
      return {
        _gte: start,
        _lt: day(start).add(1, 'day').yyyymmdd(),
      };

    return {
      _gte: start,
      _lte: end,
    };
  };

  const isNonTrustAccount = accountId === 'non-trust';

  return {
    tenantId: { _eq: tenantId },
    type: { _eq: type },
    date: getDate(),
    status: { _eq: status || 'active' },
    description: search ? { _ilike: `%${search}%` } : undefined,
    accountId: accountId
      ? isNonTrustAccount
        ? { _is_null: true }
        : { _eq: accountId }
      : undefined,
    paidStatus: paidStatus
      ? { _eq: paidStatus }
      : isNonTrustAccount
        ? { _eq: 'paid' }
        : undefined,
    lines: listingIds?.length
      ? {
          listingId: {
            _in: listingIds,
          },
        }
      : undefined,
    bankRecordPayments: bankAccountId
      ? {
          bankRecord: {
            bankAccountId: { _eq: bankAccountId },
          },
        }
      : undefined,
  };
};
