import { useInfiniteQuery } from '@finalytic/data';
import { bankRecord } from '@finalytic/graphql';
import { useBankAccountId } from '../useBankAccountId';
import { useWhereBankRecords } from './useWhereBankRecords';

export type BankRecordRow = NonNullable<
  ReturnType<typeof useBankRecordsTableQuery>['data']
>['pages'][number]['list'][number];

export const getBankRecord = (bankRecord: bankRecord) => {
  let status: NonNullable<bankRecord['reconcileStatus']> | 'excluded' =
    bankRecord.reconcileStatus || 'unpaid';

  const reconciledTransactions = bankRecord
    .transactionPayments({
      order_by: [{ transaction: { date: 'desc_nulls_last' } }],
    })
    .map((transactionPayment) => {
      const transaction = transactionPayment.transaction;

      const centTotal = transaction.centTotal ?? 0;
      const type = transaction.type;

      return {
        id: transaction.id,
        centTotal: type === 'expense' ? -centTotal : centTotal,
        description: transaction.description,
        type: transaction.type,
        date: transaction.date,
      };
    });

  if (bankRecord.status === 'inactive') {
    status = 'excluded';
  }

  return {
    id: bankRecord.id as string,
    description: bankRecord.description,
    date: bankRecord.date,
    status,
    uniqueRef: bankRecord.uniqueRef,
    centTotal: bankRecord.centTotal ?? 0,
    centBalance: bankRecord.centBalance,
    currency: 'usd',
    reconciledTransactions,
  };
};

export const useBankRecordsTableQuery = () => {
  const id = useBankAccountId();
  const where = useWhereBankRecords();

  return useInfiniteQuery(
    (q, { where }, { limit, offset }) => {
      if (!id)
        return {
          list: [],
          aggregate: 0,
        };

      const list = q
        .bankRecords({
          where,
          limit,
          offset,
          order_by: [
            {
              date: 'desc_nulls_last',
            },
          ],
        })
        .map((record) => getBankRecord(record));

      const aggregate =
        q.bankRecordAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      queryKey: 'bankRecords',
      variables: {
        where,
        id,
      },
    }
  );
};
