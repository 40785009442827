import { useInfiniteQuery } from '@finalytic/data';
import { order_by, transaction } from '@finalytic/graphql';
import { MRT_SortingState } from '@finalytic/table';
import { sum, utc } from '@finalytic/utils';
import { getDepositLines } from '../edit/get-deposit-lines';
import { useWhereDeposits } from './useWhereDeposits';

type Params = {
  sorting: MRT_SortingState;
};

export type DepositRow = NonNullable<
  ReturnType<typeof useDepositsTableQuery>['data']
>['pages'][number]['list'][number];

export const getDeposit = (
  deposit: transaction,
  opts?: { includeDetails: boolean }
) => {
  const { lines, totals } = getDepositLines(deposit.lines);

  const bankRecords = deposit
    .bankRecordPayments({
      order_by: [
        {
          bankRecord: {
            date: 'desc_nulls_last',
          },
        },
      ],
      where: {
        bankRecordId: { _is_null: false },
      },
    })
    .map((bankRecordPayment) => ({
      id: bankRecordPayment.bankRecordId,
      centTotal: bankRecordPayment.bankRecord.centTotal ?? 0,
      description: !opts?.includeDetails
        ? ''
        : bankRecordPayment.bankRecord.description,
      bankAccount: !opts?.includeDetails
        ? ''
        : bankRecordPayment.bankRecord.bankAccount?.account?.title || '',
      date: opts?.includeDetails ? bankRecordPayment.bankRecord.date : null,
    }));

  const isReconciled =
    sum(bankRecords.map((x) => x.centTotal)) === totals.centTotal;

  return {
    id: deposit.id as string,
    date: deposit.date ? utc(deposit.date).yyyymmdd() : null,
    uniqueRef: deposit.uniqueRef,
    status:
      deposit.status === 'active' ? ('Paid' as const) : ('Unpaid' as const),
    bankAccount: {
      id: deposit.accountId,
      title: deposit.account?.title,
    },
    connection: {
      id: deposit.connectionId,
      name: deposit.connection?.name,
      logo: deposit.connection?.app?.iconRound,
    },
    isReconciled,
    description: deposit.description,
    bankRecords,
    centTotal: totals.centTotal,
    currency: deposit.currency!,
    reservationLines: lines.reservationLines,
    manualLines: lines.manualLines,
    // journalEntries: opts?.includeDetails
    //   ? deposit
    //       .journalEntries({
    //         where: {
    //           status: {
    //             _neq: 'inactive',
    //           },
    //         },
    //         order_by: generalLedgerSorting,
    //       })
    //       .map((je) => ({
    //         id: je.id,
    //         txnAt: je.txnAt ? utc(je.txnAt).yyyymmdd() : null,
    //         txnNum: je.txnNum,
    //         description: je.description!,
    //         centTotal: je.centTotal!,
    //         currency: je.currency,
    //         account: je.account?.title,
    //       }))
    //   : [],
  };
};

export const useDepositsTableQuery = ({ sorting }: Params) => {
  const where = useWhereDeposits();

  return useInfiniteQuery(
    (q, { where }, { limit, offset }) => {
      const aggregate =
        q.transactionAggregate({ where }).aggregate?.count() || 0;

      const order_by = sorting.map((sort) => {
        const sortId = sort.id as 'date' | 'description';
        const order: order_by = sort.desc
          ? 'desc_nulls_last'
          : 'asc_nulls_last';

        return {
          [sortId]: order,
        };
      });

      const list = q
        .transactions({
          where,
          limit,
          offset,
          order_by,
        })
        .map((deposit) => getDeposit(deposit));

      return {
        list,
        aggregate,
      };
    },
    {
      queryKey: 'deposits',
      variables: {
        where,
        sorting,
      },
    }
  );
};
