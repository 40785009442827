import { Icon, type IconDefinition } from '@finalytic/icons';
import { day, formatCurrency } from '@finalytic/utils';
import { Card, Center, Group, rem } from '@mantine/core';
import { Text } from '@mantine/core';
import { useColorScheme } from '@mantine/hooks';
import { type ReactNode } from 'react';

export type UnreconciledBankRecord = {
  id: string;
  description: string | undefined;
  uniqueRef: string | undefined;
  date: string;
  centTotal: number;
  currency: string;
  accountId: string;
};

type Props = {
  bankRecord: UnreconciledBankRecord;
};

export const UnreconciledBankRecordCard = ({ bankRecord }: Props) => {
  return (
    <RecordCard
      title="Bank record"
      rows={[
        {
          icon: 'CircleDollarIcon',
          title: 'Amount',
          text: formatCurrency(bankRecord.centTotal / 100, bankRecord.currency),
        },
        {
          icon: 'CalendarEventIcon',
          title: 'Date',
          text: day(bankRecord.date).format('MMM D, YYYY'),
        },
        {
          icon: 'HashtagIcon',
          title: 'Reference',
          text: bankRecord.uniqueRef,
        },
        {
          icon: 'NoteTextIcon',
          title: 'Description',
          text: bankRecord.description,
        },
      ]}
    />
  );
};

const RecordCard = ({
  rows,
  title,
}: {
  rows: {
    icon: IconDefinition;
    title: string;
    text: ReactNode;
  }[];
  title?: string;
}) => {
  const [colorScheme] = useColorScheme();

  return (
    <Card
      sx={(theme) => ({
        backgroundColor:
          colorScheme === 'dark'
            ? theme.colors.dark[6]
            : theme.colors.neutral[1],
        display: 'flex',
        flexDirection: 'column',
        gap: rem(14),
        flexShrink: 0,
      })}
      radius="md"
      mb="sm"
    >
      <Text fw={500}>{title}</Text>
      {rows.map((row, index) => {
        if (!row.text) return null;

        return (
          <Group key={index} wrap="nowrap" gap="xs">
            <Center pb={2} sx={{ flexShrink: 0, width: 20 }}>
              <Icon icon={row.icon} size={16} />
            </Center>
            <Text
              component="span"
              sx={{ flex: 0.7 }}
              size="sm"
              c={colorScheme === 'dark' ? 'dimmed' : 'neutral'}
            >
              {row.title}
            </Text>
            <Text
              size="sm"
              component="span"
              sx={{
                flex: 1,
                wordBreak: 'break-all',
                whiteSpace: 'normal',
                lineBreak: 'anywhere',
              }}
            >
              {row.text}
            </Text>
          </Group>
        );
      })}
    </Card>
  );
};
