import { Filter, UserAvatar } from '@finalytic/components';
import { CheckIcon } from '@finalytic/icons';
import { LazyTable, MRT_ColumnDef } from '@finalytic/table';
import { EllipsisMenuDangerItem, EllipsisMenuItem } from '@finalytic/ui';
import { day, ensure } from '@finalytic/utils';
import {
  useDeleteInboxNotification,
  useMarkInboxNotificationAsRead,
} from '@liveblocks/react';
import { Box, Group } from '@mantine/core';
import { Text } from '@mantine/core';
import { useSetState } from '@mantine/hooks';
import { formatUserName } from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { ActivityIcon } from '../../drawers/activity-drawer/ActivityIcon';
import { NotificationRow, useNotificationsQuery } from '../../queries';

type FilterState = {
  search: string;
  level: 'read' | 'unread' | undefined;
};

export const PmInboxTable = () => {
  const [filter, setFilter] = useSetState<FilterState>({
    search: '',
    level: undefined,
  });

  const { loading, queryData, error } = useNotificationsQuery();

  const columns = useMemo(
    () =>
      ensure<MRT_ColumnDef<NotificationRow>[]>([
        {
          header: 'Icon',
          Header: '',
          size: 50,
          minSize: 50,
          maxSize: 0,
          Cell: ({ row }) => {
            return (
              <Box pos="relative">
                <UserAvatar size={33} user={row.original.actor} v2VrpIcon />
                <ActivityIcon icon={row.original.icon} />
              </Box>
            );
          },
        },
        {
          header: 'Message',
          Cell: ({ row }) => {
            const markAsRead = useMarkInboxNotificationAsRead();
            const actor = row.original.actor;
            const date = row.original.notifiedAt;

            const name = actor?.firstName ? formatUserName(actor) : 'System';
            const diff = (day(date) as any)?.fromNow?.();
            const readAt = row.original.readAt;
            const goto = useNavigate();
            const to = row.original.to;
            const id = row.original.id;
            return (
              <Box
                sx={{
                  flexGrow: 1,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (to) goto(to);
                  markAsRead(id);
                }}
              >
                <Group
                  wrap="nowrap"
                  gap="xs"
                  justify="space-between"
                  w="100%"
                  pos="relative"
                >
                  <Text component="span" c="gray" size="xs" display="block">
                    {name}
                  </Text>
                  <Text
                    component="span"
                    size="xs"
                    className="notification-date"
                    display="block"
                    c="gray"
                    sx={{
                      position: 'absolute',
                      top: 5,
                      right: 0,
                    }}
                  >
                    {!readAt && (
                      <Box
                        sx={(theme) => ({
                          display: 'inline-block',
                          width: 5,
                          height: 5,
                          marginBottom: 2,
                          marginRight: 8,
                          backgroundColor: theme.colors[theme.primaryColor][6],
                          borderRadius: '100%',
                        })}
                      />
                    )}
                    {diff}
                  </Text>
                </Group>
                {row.original.content}
              </Box>
            );
          },
        },
      ]),
    []
  );

  const readOptions = useMemo(
    () => [
      {
        label: 'Read',
        value: 'read' as const,
      },
      {
        label: 'Unread',
        value: 'unread' as const,
      },
    ],
    []
  );

  const rowData = queryData?.all || [];

  const search = filter.search?.trim();

  const filteredData = useMemo(() => {
    const hasActiveFilter = Object.values(filter).some((x) => x);
    if (!hasActiveFilter) return rowData;

    return rowData.filter((x) => {
      const hasSearch = search ? x.content?.toString().includes(search) : true;
      const hasReadFilter = filter.level
        ? filter.level === 'read'
          ? x.readAt
          : !x.readAt
        : true;

      return hasSearch && hasReadFilter;
    });
  }, [rowData, search, filter.level]);

  return (
    <>
      <LazyTable
        table={{
          columns,
          emptyRowsFallback: 'No notifications found',
          hideHeader: true,
        }}
        data={{
          rowCount: filteredData.length,
          rows: filteredData,
          loading,
          error: error?.message,
        }}
        resetFilter={() => setFilter({ search: undefined })}
        rowMenu={{
          menuItems: ({ row }) => {
            const deleteNotification = useDeleteInboxNotification();
            const markAsRead = useMarkInboxNotificationAsRead();
            const id = row.original.id;

            return (
              <>
                <EllipsisMenuItem
                  onClick={() => markAsRead(id)}
                  customIcon={<CheckIcon size={18} />}
                >
                  Mark as read
                </EllipsisMenuItem>
                <EllipsisMenuDangerItem onClick={() => deleteNotification(id)}>
                  Delete
                </EllipsisMenuDangerItem>
              </>
            );
          },
        }}
      >
        <Group>
          <Filter.Search
            value={filter.search || ''}
            setValue={(search) => setFilter({ search })}
          />
          <Filter.Select
            type="single"
            label="Status"
            data={{
              options: readOptions,
            }}
            value={readOptions.find((x) => x.value === filter.level) || null}
            setValue={(v) => setFilter({ level: v?.value })}
          />
        </Group>
      </LazyTable>
    </>
  );
};
