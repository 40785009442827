import { InputSelect } from '@finalytic/components';
import { useQuery } from '@finalytic/data';
import { currency_enum } from '@finalytic/graphql';
import { SelectItem } from '@finalytic/ui';
import { sortBy, toTitleCase } from '@finalytic/utils';
import { currencies } from '@vrplatform/ui-common';
import { useMemo } from 'react';

type Props = {
  value: currency_enum | null;
  setValue: (value: SelectItem<currency_enum> | null) => void;
  error: boolean;
};

export const InputCurrency = ({ setValue, value: initial, error }: Props) => {
  const {
    data,
    isLoading: loading,
    error: errorQuery,
  } = useQuery(
    (q, args) => {
      const currencies = args.availableCurrencies;

      const c = q
        .currency({
          order_by: [{ name: 'asc_nulls_last' }],
        })
        .map<SelectItem<currency_enum>>((item) => ({
          label:
            currencies[
              (item.name?.toUpperCase() || '') as keyof typeof currencies
            ]?.currencyName ||
            toTitleCase(item.name) ||
            '',
          value: item.name as currency_enum,
        }));

      return sortBy(c, 'label', 'asc');
    },
    {
      variables: {
        availableCurrencies: currencies,
      },
      keepPreviousData: true,
    }
  );

  const { options, pinned, all } = useMemo(() => {
    const opts = data || [];

    const pinned = ['usd', 'eur', 'gbp'];

    return {
      options: opts.filter((o) => !pinned.includes(o.value)),
      pinned: opts.filter((o) => pinned.includes(o.value)),
      all: opts,
    };
  }, [data]);

  const value = useMemo(
    () => all.find((o) => o.value === initial) || null,
    [all, initial]
  );

  return (
    <InputSelect
      type="single"
      value={value}
      setValue={setValue}
      data={{
        options,
        loading,
        error: errorQuery,
        sort: null,
      }}
      inputProps={{
        error,
        disabled: !!errorQuery,
        placeholder: options[0]?.label || 'Select type',
        loadingQuery: loading,
      }}
      dropdownProps={{
        width: 'target',
      }}
      pinnedItems={pinned}
    />
  );
};
