import { accountAssignmentType_enum } from '@finalytic/graphql';

export const formatAssignmentType = (
  assignmentType: accountAssignmentType_enum | undefined
) => {
  if (!assignmentType) return '';

  const titles: Record<accountAssignmentType_enum, string> = {
    deposit_merchantFee: 'Deposit Merchant Fee',
    deferredRevenue: 'Deferred Revenue',
    accountsPayable: 'Accounts Payable',
    accountsReceivable: 'Accounts Receivable',
    expense_reimbursement: 'Expense Reimbursement',
    deposit_reserve: 'Deposit Reserve',
    deposit_channelFee: 'Deposit Channel Fee',
    expense_markup: 'Expense Markup',
    transfer: 'Owner Statement Credit',
    cancellationRevenue: 'Cancellation Revenue',
  };

  return titles[assignmentType] || assignmentType;
};
