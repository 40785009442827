import { Breadcrumbs, Button } from '@finalytic/components';
import { Group } from '@mantine/core';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { EXPENSE_FORM_ID, ExpenseForm } from './ExpenseForm';
import { ExpenseFormInputs, useExpenseFormProvider } from './useExpenseForm';
import { useExpenseFormMutation } from './useExpenseFormMutation';
import { useExpenseFormQuery } from './useExpenseFormQuery';

export const ExpenseEditView = () => {
  const { isLoading, data: queryData } = useExpenseFormQuery();

  const methods = useExpenseFormProvider(queryData?.values ?? undefined);
  const { mutate } = useExpenseFormMutation({ bankRecordId: undefined });
  const goto = useNavigate();

  const onReset = () => {
    methods.reset();
    goto('/expenses');
  };

  const onSubmit = async (values: ExpenseFormInputs) => {
    try {
      await mutate(values).then(onReset);
    } catch (error: any) {
      console.error(error);
    }
  };

  return (
    <FormProvider {...methods}>
      {/* Header / Breadcrumbs */}
      <Group justify="space-between" mb="xl">
        <Breadcrumbs
          crumbs={[
            {
              href: '/expenses',
              title: 'Expenses',
            },
          ]}
          pageTitle={queryData?.values ? 'Edit Expense' : 'New Expense'}
        />
        <Group>
          <Button
            type="reset"
            disabled={methods.formState.isSubmitting}
            form={EXPENSE_FORM_ID}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            loading={methods.formState.isSubmitting}
            form={EXPENSE_FORM_ID}
          >
            Save
          </Button>
        </Group>
      </Group>

      <ExpenseForm loading={isLoading} onReset={onReset} onSubmit={onSubmit} />
    </FormProvider>
  );
};
