import { useTrpcMutation, useTrpcQuery } from '@finalytic/data';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import {
  CopyIcon,
  Edit3Icon,
  EmailIcon,
  EyeIcon,
  Link2Icon,
  RefreshCwIcon,
} from '@finalytic/icons';
import {
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
  StringParam,
  showSuccessNotification,
  useQueryParamsSet,
} from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import { faUserMinus } from '@fortawesome/pro-solid-svg-icons';
import { Box, Tooltip } from '@mantine/core';

type Props = {
  owner: {
    id: Maybe<string>;
    teamId: Maybe<string>;
    firstName: Maybe<string>;
    lastName: Maybe<string>;
    companyName: Maybe<string>;
    email: Maybe<string>;
  };
  isReinvite: boolean;
  refetch: () => void;
};

function useOwnerHasPortalAccess({
  ownerId,
  teamId,
}: { ownerId: string; teamId: string }) {
  const { data, loading: loadingQuery } = useTrpcQuery(
    'fetchClerkUser',
    {
      teamId,
      userId: ownerId,
    },
    {
      skip: !ownerId || !teamId,
    }
  );

  const hasAccess = !!data?.publicMetadata?.allowed_ids?.includes(teamId);

  return {
    hasAccess,
    loadingQuery,
  };
}

export const OwnerUserEllipsisMenuItems = ({
  isReinvite,
  owner: { id: ownerId, teamId },
  handlers: {
    setDeleteOpen,
    setInviteOpen,
    setRevokeAccessOpen,
    setCopyInviteOpen,
  },
}: Props & {
  handlers: {
    setInviteOpen: () => void;
    setCopyInviteOpen: () => void;
    setDeleteOpen: () => void;
    setRevokeAccessOpen: () => void;
  };
}) => {
  const setView = useQueryParamsSet({
    view: StringParam,
    owner: StringParam,
  });

  if (!ownerId || !teamId) return null;

  return (
    <>
      <EllipsisMenuItem
        onClick={() =>
          setView({
            view: 'edit',
          })
        }
        customIcon={<Edit3Icon size={18} />}
      >
        Edit user
      </EllipsisMenuItem>
      <EllipsisMenuDivider />

      <OwnerImpersonationEllipsisItem ownerId={ownerId} teamId={teamId} />

      <HiddenFeatureIndicator permission="partner-admin">
        <OwnerRevokeAccessMenuButton
          openModal={() => setRevokeAccessOpen()}
          ownerId={ownerId}
          teamId={teamId}
        />
      </HiddenFeatureIndicator>

      <EllipsisMenuDivider />
      <EllipsisMenuItem
        onClick={() => setInviteOpen()}
        customIcon={
          isReinvite ? <RefreshCwIcon size={18} /> : <EmailIcon size={18} />
        }
      >
        {isReinvite ? 'Resend email invitation' : 'Email invite to app'}
      </EllipsisMenuItem>

      {isReinvite && (
        <EllipsisMenuItem
          onClick={setCopyInviteOpen}
          customIcon={<Link2Icon size={18} />}
        >
          Copy invitation url
        </EllipsisMenuItem>
      )}

      <EllipsisMenuDivider />

      <EllipsisMenuDangerItem onClick={() => setDeleteOpen()}>
        Delete
      </EllipsisMenuDangerItem>

      <HiddenFeatureIndicator permission="super-admin">
        <EllipsisMenuItem
          customIcon={<CopyIcon size={18} />}
          disabled={!ownerId}
          onClick={() =>
            navigator.clipboard.writeText(`${ownerId}`).then(() =>
              showSuccessNotification({
                message: 'The ID was added to your clipboard.',
              })
            )
          }
        >
          Copy user ID
        </EllipsisMenuItem>
      </HiddenFeatureIndicator>
    </>
  );
};

type BaseMenuButtonProps = { ownerId: string; teamId: string };

interface OwnerRevokeAccessMenuButtonProps extends BaseMenuButtonProps {
  openModal: () => void;
}
const OwnerRevokeAccessMenuButton = ({
  openModal,
  ownerId,
  teamId,
}: OwnerRevokeAccessMenuButtonProps) => {
  const { hasAccess, loadingQuery } = useOwnerHasPortalAccess({
    ownerId,
    teamId,
  });

  return (
    <Tooltip
      disabled={hasAccess || loadingQuery}
      label="Owner does not currently have access to your team."
      withArrow
    >
      <Box>
        <EllipsisMenuItem
          onClick={openModal}
          icon={faUserMinus}
          loading={loadingQuery}
          disabled={!hasAccess}
        >
          Revoke Portal Access
        </EllipsisMenuItem>
      </Box>
    </Tooltip>
  );
};

const OwnerImpersonationEllipsisItem = ({
  ownerId,
  teamId,
}: BaseMenuButtonProps) => {
  const { hasAccess, loadingQuery } = useOwnerHasPortalAccess({
    ownerId,
    teamId,
  });

  const { mutate, loading } = useTrpcMutation('impersonateUser', {
    successMessage: {
      title: 'Owner Preview',
      message: 'Successfully created owner preview.',
    },
  });

  const createPreviewV2 = () =>
    mutate({ userId: ownerId, tenantId: teamId }).then((res) => {
      console.log(res);
      window.open(res.url);
    });

  return (
    <Tooltip
      disabled={hasAccess || loadingQuery}
      label="Owner has not been invited to the owner portal."
      withArrow
    >
      <Box>
        <EllipsisMenuItem
          customIcon={<EyeIcon size={18} />}
          onClick={createPreviewV2}
          loading={loading || loadingQuery}
          disabled={!hasAccess}
        >
          User Impersonation
        </EllipsisMenuItem>
      </Box>
    </Tooltip>
  );
};
