import { useInfiniteQuery } from '@finalytic/data';
import {
  activeStatus_enum,
  order_by,
  taxBehavior_enum,
  transaction,
  transaction_order_by,
} from '@finalytic/graphql';
import { MRT_SortingState } from '@finalytic/table';
import { sum, utc } from '@finalytic/utils';
import { formatOwnerName, getListingName } from '@vrplatform/ui-common';
import { useWhereExpenses } from './useWhereExpenses';

type Params = {
  sorting: MRT_SortingState;
  status: activeStatus_enum;
};

export type ExpenseRow = NonNullable<
  ReturnType<typeof useExpenseTableQuery>['data']
>['pages'][number]['list'][number];

type Markup = {
  id: any;
  centTotal: number | undefined;
  rate: any;
  taxRateId: any;
  taxBehavior: taxBehavior_enum | null;
};

export const getExpense = (
  expense: transaction,
  opts?: {
    includeDetails: boolean;
  }
) => {
  const lines = expense
    .lines({
      order_by: [{ createdAt: 'asc_nulls_last' }],
    })
    .map((line) => {
      const rateAddition = line.additions({
        limit: 1,
        where: {
          type: { _eq: 'tax' },
        },
        order_by: [{ updatedAt: 'desc_nulls_last' }],
      })[0];

      const markupAddition = line
        .additions({
          where: {
            type: { _eq: 'markup' },
          },
          order_by: [{ updatedAt: 'desc_nulls_last' }],
          limit: 1,
        })
        .map<Markup | undefined>((markup) => ({
          id: markup.id,
          centTotal: markup.centTotal,
          rate: null,
          taxRateId: null,
          taxBehavior: null,
        }))[0];

      return {
        id: line.id,
        accountId: line.accountId,
        centTotal: line.centTotal ?? 0,
        description: line.description || '',
        rateBehavior: rateAddition?.behavior || null,
        rateId: rateAddition?.rateId || null,
        ratePercentage: rateAddition?.percentage ?? 0,
        markup: markupAddition,
        listing: {
          id: line.listing?.id,
          name: getListingName(line.listing) || 'No name',
        },
        reservation: {
          id: line.reservation?.id,
          confirmationCode: line.reservation?.confirmationCode,
          guestName: line.reservation?.guestName,
          guests: line.reservation?.guests,
          checkIn: line.reservation?.checkIn,
          checkOut: line.reservation?.checkOut,
        },
      };
    });

  const bankRecords = expense
    .bankRecordPayments({
      order_by: [
        {
          bankRecord: {
            date: 'desc_nulls_last',
          },
        },
      ],
      where: {
        bankRecordId: { _is_null: false },
      },
    })
    .map((bankRecordPayment) => ({
      id: bankRecordPayment.bankRecordId,
      centTotal: bankRecordPayment.bankRecord.centTotal ?? 0,
      description: !opts?.includeDetails
        ? ''
        : bankRecordPayment.bankRecord.description,
      bankAccount: !opts?.includeDetails
        ? ''
        : bankRecordPayment.bankRecord.bankAccount?.account?.title || '',
      date: opts?.includeDetails ? bankRecordPayment.bankRecord.date : null,
    }));

  const centTotal = sum(
    lines.map((line) => line.centTotal + (line.markup?.centTotal || 0))
  );

  const isReconciled = sum(bankRecords.map((x) => x.centTotal)) === -centTotal;

  return {
    id: expense.id,
    date: expense.date ? utc(expense.date).yyyymmdd() : null,
    paidStatus: expense.paidStatus,
    paidAt: expense.paidAt ? utc(expense.paidAt).yyyymmdd() : null,
    bankAccount: {
      id: expense.accountId,
      title: expense.account?.title,
    },
    bankRecords,
    isReconciled,
    contact: opts?.includeDetails
      ? {
          id: expense.contactId,
          name: formatOwnerName(expense.contact, { showEmpty: true }),
        }
      : null,
    description: expense.description,
    type: expense.type,
    uniqueRef: expense.uniqueRef,
    centTotal,
    currency: expense.currency!,
    lines,
    connection: {
      id: expense.connectionId,
      name: expense.connection?.name,
      logo: expense.connection?.app?.iconRound,
    },
    // journalEntries: opts?.includeDetails
    //   ? expense
    //       .journalEntries({
    //         where: {
    //           status: {
    //             _neq: 'inactive',
    //           },
    //         },
    //         order_by: generalLedgerSorting,
    //       })
    //       .map((je) => ({
    //         id: je.id,
    //         txnAt: je.txnAt,
    //         txnNum: je.txnNum,
    //         description: je.description!,
    //         centTotal: je.centTotal!,
    //         currency: je.currency,
    //         account: je.account?.title,
    //       }))
    //   : [],
  };
};

export const useExpenseTableQuery = ({ sorting, status }: Params) => {
  const where = useWhereExpenses(status);

  return useInfiniteQuery(
    (q, { where }, { limit, offset }) => {
      const aggregate =
        q.transactionAggregate({ where }).aggregate?.count() || 0;

      const order_by = sorting.map<transaction_order_by>((sort) => {
        const sortId = sort.id as 'date' | 'description' | 'accountId';
        const order: order_by = sort.desc
          ? 'desc_nulls_last'
          : 'asc_nulls_last';

        if (sortId === 'accountId') {
          return {
            account: {
              title: order,
            },
          };
        }

        return {
          [sortId]: order,
        };
      });

      const list = q
        .transactions({
          where,
          limit,
          offset,
          order_by,
        })
        .map((expense) => getExpense(expense));

      return {
        list,
        aggregate,
      };
    },
    {
      queryKey: 'expenses',
      variables: {
        where,
        sorting,
      },
    }
  );
};
