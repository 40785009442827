import { taxBehavior_enum } from '@finalytic/graphql';
import { LoadingIndicator } from '@finalytic/ui';
import { bankersRound, formatCurrency, toTitleCase } from '@finalytic/utils';
import { Text } from '@mantine/core';
import { Group, HoverCard, Stack } from '@mantine/core';
import { formatPercentage } from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { useTaxRatesQuery } from '../../../queries';

export const DepositLineAmountHoverCard = ({
  currency,
  ...data
}: {
  centTotal: number;
  merchantFeeCentTotal?: number;
  channelFeeCentTotal?: number;
  rateId?: string | null;
  rateBehavior?: taxBehavior_enum;
  currency: string;
}) => {
  const { data: rates, isLoading: loadingRates } = useTaxRatesQuery();

  const rate = data.rateId
    ? rates?.find((x) => x.value === data.rateId)
    : undefined;

  const total = useMemo(() => {
    const appliedAmount =
      rate && data.rateBehavior === 'excluded'
        ? bankersRound(data.centTotal + data.centTotal * rate.percentage)
        : data.centTotal;

    return (
      appliedAmount -
      (data.merchantFeeCentTotal || 0) -
      (data.channelFeeCentTotal || 0)
    );
  }, [
    data.centTotal,
    data.channelFeeCentTotal,
    data.merchantFeeCentTotal,
    rate,
  ]);

  return (
    <HoverCard
      position="bottom-end"
      withArrow
      withinPortal
      width={250}
      shadow="md"
      radius="md"
      arrowOffset={10}
    >
      <HoverCard.Target>
        <Text>{formatCurrency(total / 100, currency)}</Text>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Stack gap="xs">
          <Group wrap="nowrap" align="flex-start">
            <Text c="gray" flex={1}>
              Amount applied
            </Text>
            <Text ta="right" fw={500}>
              {formatCurrency(data.centTotal / 100, currency)}
            </Text>
          </Group>
          {'rateId' in data && !!data.rateId && (
            <Group wrap="nowrap" align="flex-start">
              <Text c="gray" flex={1}>
                Tax Rate{' '}
                <Text span size="xs" c="gray">
                  ({toTitleCase(data.rateBehavior)})
                </Text>
              </Text>
              {loadingRates ? (
                <LoadingIndicator size="xs" />
              ) : (
                <Text ta="right" fw={500}>
                  {formatPercentage(
                    rates?.find((x) => x.value === data.rateId)?.percentage || 0
                  )}
                </Text>
              )}
            </Group>
          )}
          {'merchantFeeCentTotal' in data && !!data.merchantFeeCentTotal && (
            <Group wrap="nowrap" align="flex-start">
              <Text c="gray" flex={1}>
                Merchant fee
              </Text>
              <Text ta="right" fw={500}>
                {formatCurrency(
                  ((data.merchantFeeCentTotal ?? 0) * -1) / 100,
                  currency
                )}
              </Text>
            </Group>
          )}
          {'channelFeeCentTotal' in data && !!data.channelFeeCentTotal && (
            <Group wrap="nowrap" align="flex-start">
              <Text c="gray" flex={1}>
                Channel fee
              </Text>
              <Text ta="right" fw={500}>
                {formatCurrency(
                  ((data.channelFeeCentTotal ?? 0) * -1) / 100,
                  currency
                )}
              </Text>
            </Group>
          )}
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};
