import { day, hasValue } from '@finalytic/utils';
import * as Sentry from '@sentry/react';
import {
  GroupProps,
  IdentifyProps,
  TrackingEvent as LogTrackingEvent,
  isTest,
} from '@vrplatform/log';
import { formatUserName } from '@vrplatform/ui-common';
import { useCallback, useMemo } from 'react';
import { GRAPHQL_URI } from '../env';
import { useGqtyClient, useMutation } from '../graphql';
import { captureSentryError } from './sentry';
import { useMe, useTeam } from './team';
import { Intercom, useIntercom } from './useIntercom';

export type Tracking = ReturnType<typeof useTracking>;
export type TrackingEvent = LogTrackingEvent;

const isLocalhost =
  window.location.host.includes('localhost') ||
  window.location.host.includes('127.0.0.1');
const isStaging = window.location.hostname === 'staging.portal.vrplatform.app';
const isProduction = window.location.hostname === 'portal.vrplatform.app';
const isExtension = window.location.href.startsWith('chrome-extension');
const enableTracking = !isLocalhost && !isExtension;

export function useTrackingInit() {
  return useMemo(() => {
    if (!enableTracking) return;

    // INTERCOM
    Intercom({
      custom_launcher_selector: '#intercom-widget',
      alignment: 'right',
      horizontal_padding: 40,
      vertical_padding: 40,
    });

    // SENTRY
    Sentry.init({
      dsn: 'https://4ba9b6d2701d4a889e6f323c050a4a46@o515442.ingest.sentry.io/5624987',
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 1.0,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      environment: import.meta.env.DEV
        ? 'development'
        : isProduction
          ? 'production'
          : 'staging',
      tracesSampleRate: 0.1,
      tunnel: 'https://pomegranate.vrplatform.app',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllInputs: false,
          maskAllText: false,
          blockAllMedia: false,
          networkDetailAllowUrls: [
            GRAPHQL_URI,
            'https://clerk.vrplatform.app/v1/client/.*',
            'https://cdn.segment.com/v1/projects.*',
          ],
          networkCaptureBodies: true,
          networkResponseHeaders: ['X-Request-Id'],
          networkRequestHeaders: ['Finalytic-Platform'],
        }),
        isStaging
          ? Sentry.feedbackIntegration({
              colorScheme: 'light',
              showEmail: false,
              showName: false,
              showBranding: false,
              autoInject: false,
              themeLight: {
                submitBackground: '#025FCA',
                submitBackgroundHover: '#024797',
                submitBorder: '#025FCA',
                submitOutlineFocus: '#025FCA',
              },
            })
          : undefined,
      ].filter(hasValue),
    });
  }, []);
}

export function useTracking() {
  const { trackEvent, update, shutdown } = useIntercom();
  const [{ id: tenantId }] = useTeam();
  const {
    id: userId,
    email: userEmail,
    firstName,
    lastName,
    createdAt,
  } = useMe();
  const gqt = useGqtyClient();

  const { mutate } = useMutation(
    (
      q,
      args: {
        event: string;
        tenantId: string;
        userId: string;
        properties?: Record<string, any>;
      }
    ) =>
      q.insert_tracking_event_one({
        object: {
          name: args.event,
          tenantId: args.tenantId,
          userId: args.userId,
          props: args.properties,
          packageName: 'ui',
        },
      })?.id,
    {
      hideNotification: true,
    }
  );

  if (isTest(userId, tenantId, userEmail))
    return {
      track: async () => {},
      identify: () => {},
      group: () => {},
      page: () => {},
      reset: () => {},
    };

  return {
    track: useCallback(
      async (
        event: LogTrackingEvent,
        data: {
          userId?: string;
          tenantId?: string;
          userEmail?: string;
          [key: string]: string | number | boolean | null | undefined;
        }
      ) => {
        const meta = { userId, tenantId, userEmail, ...data };

        if (isTest(meta.userId, meta.tenantId, meta.userEmail)) return;

        if (gqt)
          await mutate({
            args: {
              event,
              tenantId: meta.tenantId,
              userId: meta.userId,
              properties: meta,
            },
          }).catch((err) => {
            captureSentryError(err);
          });
        else
          console.warn(
            `GQTY client not found. Tracking event ${event} will not work!`,
            meta
          );

        trackEvent?.(event, meta);
      },
      [userId, tenantId, userEmail, gqt]
    ),
    identify: useCallback((userId: string, traits: IdentifyProps['traits']) => {
      update?.({
        user_id: userId,
        email: traits?.email || userEmail,
        created_at:
          traits?.createdAt || createdAt
            ? day(traits?.createdAt || createdAt).unix()
            : undefined,
        name: formatUserName(
          {
            firstName: traits?.firstName || firstName,
            lastName: traits?.lastName || lastName,
          },
          { lastNameFirst: false, showEmpty: true }
        ),
        last_request_at: day().unix(),
        last_seen_at: traits?.lastSeenAt
          ? day(traits?.lastSeenAt).unix()
          : day().unix(),
        avatar: traits?.avatar,
        // custom properties
        userType: traits?.role,
      });
    }, []),
    group: useCallback((groupId: string, traits: GroupProps['traits'] = {}) => {
      // add teamId to traits
      if (!traits?.teamId) traits.teamId = groupId;

      const cid = traits?.billingCustomerId || traits?.hyperlineCustomerId;

      update?.({
        user_id: userId,
        company: {
          company_id: groupId,
          name: traits?.name,
          created_at: traits?.createdAt
            ? new Date(traits.createdAt).getTime() / 1000
            : undefined,
          plan: traits?.plan,
          monthly_spend: traits?.mrr,
          // custom properties
          teamId: groupId,
          team: traits?.name,
          type: traits?.type,
          status: traits?.status,
          pms: traits?.pms,
          accountingSoftware: traits?.accountingSoftware,
          activeListings: traits?.activeListings,
          paymentMethodType: traits?.paymentMethodType,
          partnerName: traits?.partner || traits?.accountingPartner,
          billingPortalUrl: cid
            ? `https://billing.vrplatform.app/portal/${cid}`
            : undefined,
          billingStatus: traits?.billingSubscriptionStatus,
          billingPartner:
            traits?.billingPartner ||
            traits?.partner ||
            traits?.accountingPartner,
        },
      });
    }, []),
    page: useCallback(
      (_?: string, name?: string) =>
        update?.({ user_id: userId, page_title: name }),
      []
    ),
    reset: shutdown,
  };
}
