import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import { Icon } from '@finalytic/icons';
import {
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
  showSuccessNotification,
} from '@finalytic/ui';
import { useNavigate } from 'react-router';
import { useJournalEntriesModal } from '../../../modals';
import { DepositRow } from './useDepositsTableQuery';

export const DepositEllipsisMenuItems = ({
  deposit,
  openDeleteModal,
}: {
  deposit: DepositRow;
  openDeleteModal: () => void;
}) => {
  const goto = useNavigate();

  const isSynced = deposit.connection.id;

  const { open: openJournalEntriesModal } = useJournalEntriesModal();

  return (
    <>
      <EllipsisMenuItem
        onClick={() => goto(`/deposits/${deposit.id}/edit`)}
        customIcon={<Icon icon={'Edit3Icon'} size={16} />}
      >
        Edit
      </EllipsisMenuItem>

      {!isSynced && (
        <>
          <EllipsisMenuDivider />

          <EllipsisMenuDangerItem onClick={openDeleteModal}>
            Delete
          </EllipsisMenuDangerItem>
        </>
      )}

      <HiddenFeatureIndicator permission="vrp-admin">
        <EllipsisMenuItem
          customIcon={<Icon icon="ListUnorderedIcon" size={16} />}
          onClick={() =>
            openJournalEntriesModal({
              transactionId: {
                _eq: deposit.id,
              },
            })
          }
        >
          Journal Entries
        </EllipsisMenuItem>

        <EllipsisMenuItem
          customIcon={
            <Icon
              icon="CopyIcon"
              size={16}
              color={(theme) => theme.colors.neutral[5]}
            />
          }
          disabled={!deposit.id}
          onClick={() => {
            navigator.clipboard.writeText(`${deposit.id}`).then(() =>
              showSuccessNotification({
                message: 'Deposit ID was added to your clipboard.',
              })
            );
          }}
        >
          Copy ID
        </EllipsisMenuItem>
      </HiddenFeatureIndicator>
    </>
  );
};
