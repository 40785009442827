import { useLegacyColors } from '@finalytic/ui';
import {
  Anchor,
  Text,
  Title,
  TitleProps,
  useMantineColorScheme,
} from '@mantine/core';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  crumbs?: { title: string; href: string }[];
  pageTitle: string;
};
export const Breadcrumbs = ({
  crumbs,
  pageTitle,
  children: _,
  ...props
}: Props & TitleProps) => {
  const { colorScheme } = useMantineColorScheme();
  const { gray } = useLegacyColors();
  const isDarkMode = colorScheme === 'dark';

  return (
    <Title fw={400} c={gray.dark + 90} order={1} size="1.5rem" {...props}>
      {crumbs?.map((crumb) => (
        <Fragment key={crumb.title}>
          <Anchor
            inherit
            component={Link}
            c={isDarkMode ? gray.light : gray.dark + 90}
            to={crumb.href}
          >
            {crumb.title}
          </Anchor>{' '}
          /{' '}
        </Fragment>
      ))}
      <Text
        component="span"
        fw={'bold'}
        c={colorScheme === 'dark' ? 'white' : 'dark'}
        inherit
      >
        {pageTitle}
      </Text>
    </Title>
  );
};
