import { Icon, IconDefinition } from '@finalytic/icons';
import {
  ActionIcon,
  ActionIconProps,
  Indicator,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { ComponentProps, ReactNode, forwardRef } from 'react';

type Props = {
  count?: number;
  size?: number;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  icon: IconDefinition;
  strokeWidth?: number;
  color?: ComponentProps<typeof Icon>['color'];
  tooltip?: ReactNode;
} & Omit<
  ActionIconProps,
  'onClick' | 'children' | 'color' | 'size' | 'variant' | 'sx'
>;

export const IconButton = forwardRef<HTMLButtonElement, Props>(
  (
    { onClick, icon, size = 18, color, count, tooltip, strokeWidth, ...props },
    ref
  ) => {
    const theme = useMantineTheme();

    return (
      <Tooltip
        label={tooltip}
        withArrow
        disabled={!tooltip || (typeof tooltip === 'string' && !tooltip?.trim())}
      >
        <Indicator
          label={count}
          color={theme.colors.red[9]}
          position="top-end"
          styles={() => ({
            indicator: {
              height: 15,
              width: 16,
              fontSize: 11,
              borderRadius: '100%',
            },
          })}
          offset={0}
          disabled={!count}
        >
          <ActionIcon
            onClick={onClick}
            ref={ref}
            variant="transparent"
            sx={(theme) => ({
              borderRadius: theme.radius.sm,
              padding: theme.spacing.xs,
              ':hover': {
                backgroundColor: theme.colors.gray[0] + 10,
              },
            })}
            {...props}
          >
            <Icon
              icon={icon}
              color={color}
              size={size}
              strokeWidth={strokeWidth}
            />
          </ActionIcon>
        </Indicator>
      </Tooltip>
    );
  }
);
