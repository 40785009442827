import { useEnabledFeatures, useQuery, useTeamId } from '@finalytic/data';
import { utc } from '@finalytic/utils';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { type ExpenseFormInputs } from './useExpenseForm';

export function useExpenseFormQuery() {
  const { id } = useParams();
  const [teamId] = useTeamId();
  const { GL } = useEnabledFeatures();

  const goto = useNavigate();

  const queryData = useQuery(
    (
      q,
      args
    ): {
      values: ExpenseFormInputs | null;
    } => {
      if (!args.transactionId || !args.teamId)
        return {
          values: null,
        };

      const values =
        q
          .transactions({
            where: {
              tenantId: { _eq: args.teamId },
              id: { _eq: args.transactionId },
            },
          })
          .map<ExpenseFormInputs>((transaction) => {
            return {
              id: transaction.id as string,
              date: utc(transaction.date).yyyymmdd() as string,
              contactId: (transaction.contactId ?? null) as string | null,
              description: transaction.description || '',
              uniqueRef: transaction.uniqueRef || '',
              paidStatus: transaction.paidStatus || 'paid',
              paidAt: transaction.paidAt
                ? utc(transaction.paidAt).yyyymmdd()
                : null,
              accountId:
                (transaction.accountId as string | null) || 'non-trust',
              currency: transaction.currency || 'usd',
              lines: transaction
                .lines()
                .map<ExpenseFormInputs['lines'][number]>((line) => {
                  const rateAddition = line.additions({
                    limit: 1,
                    where: {
                      rateId: { _is_null: false },
                    },
                    order_by: [{ updatedAt: 'desc_nulls_last' }],
                  })[0];

                  const markupAddition = line.additions({
                    limit: 1,
                    where: {
                      type: { _eq: 'markup' },
                    },
                    order_by: [{ updatedAt: 'desc_nulls_last' }],
                  })[0];

                  return {
                    id: line.id,
                    description: line.description || '',
                    listingId: line.listingId,
                    reservationId: line.reservationId,
                    centTotal: line.centTotal ?? 0,
                    party: line.party || 'manager',
                    accountId: line.accountId,
                    markup: markupAddition?.centTotal ?? 0,
                    rateBehavior: rateAddition?.behavior || null,
                    rateId: rateAddition?.rateId || null,
                  };
                }),
            };
          })[0] || null;

      return {
        values,
      };
    },
    {
      variables: {
        transactionId: id,
        teamId: teamId,
      },
    }
  );

  useEffect(() => {
    if (queryData?.data?.values === null && id) {
      if (GL) goto('/expenses');
      else goto('/');
    }
  }, [queryData?.data?.values, GL, goto, id]);

  return queryData;
}
