import { InputSelect, InputWrapper } from '@finalytic/components';
import { useInfiniteQuery, useQuery, useTeamId } from '@finalytic/data';
import { contact_bool_exp } from '@finalytic/graphql';
import { Icon } from '@finalytic/icons';
import { SelectItem } from '@finalytic/ui';
import { toTitleCase } from '@finalytic/utils';
import { Box } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  formatOwnerName,
  orderByOwner,
  whereContacts,
} from '@vrplatform/ui-common';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { ContactDrawer } from '../../../../drawers/contact-drawer';
import { useExpenseForm } from '../useExpenseForm';

export const ExpenseContactInput = () => {
  const methods = useExpenseForm();
  const [teamId] = useTeamId();

  const [opened, handlers] = useDisclosure();
  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, { teamId, search }, { limit, offset }) => {
      if (!teamId)
        return {
          list: [],
          aggregate: 0,
        };

      const where: contact_bool_exp = whereContacts({
        search,
        tenantId: teamId,
        type: 'vendor',
      });

      const list = q
        .contacts({
          where,
          order_by: [orderByOwner],
          limit,
          offset,
        })
        .map<SelectItem>((res) => ({
          value: res.id,
          label: formatOwnerName(res) || 'No name',
          group: toTitleCase(res.type),
        }));

      const aggregate = q.contactAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      queryKey: ['contacts'],
      variables: {
        teamId,
        search: search?.trim(),
      },
    }
  );
  return (
    <Box>
      <ContactDrawer closeDrawer={handlers.close} opened={opened} />
      <Controller
        control={methods.control}
        name={'contactId'}
        rules={{
          required: 'Please select a contact',
        }}
        render={({ field, fieldState }) => {
          const { data, isLoading: loading } = useQuery(
            (q, args) => {
              if (!args.value) return null;

              return q
                .contacts({
                  where: {
                    id: { _eq: args.value },
                  },
                })
                .map<SelectItem>((item) => ({
                  label: formatOwnerName(item) || 'No name',
                  value: item.id!,
                }))[0];
            },
            {
              keepPreviousData: true,
              variables: {
                value: field.value,
              },
            }
          );

          const contact = field.value ? data || null : null;

          return (
            <InputWrapper error={fieldState.error?.message}>
              <InputSelect
                type="single"
                value={contact}
                setValue={(v) => field.onChange(v?.value || null)}
                infiniteData={{ ...queryData, setSearch }}
                inputProps={{
                  error: !!fieldState.error,
                  placeholder:
                    queryData.data?.pages?.[0]?.list?.[0]?.label ||
                    'Select Contact',
                  loadingQuery: loading,
                  withClearButton: true,
                }}
                dropdownProps={{
                  width: 'target',
                }}
                customBottomActions={[
                  {
                    id: 'add-contact',
                    label: 'Add contact',
                    onSubmit: () => handlers.open(),
                    description: 'Add a new contact in case your missing one.',
                    icon: <Icon icon="PlusIcon" size={16} />,
                  },
                ]}
              />
            </InputWrapper>
          );
        }}
      />
    </Box>
  );
};
