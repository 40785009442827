import { InputSelect, InputWrapper } from '@finalytic/components';
import { gqlV2, useTeam } from '@finalytic/data';
import { useInfiniteQuery, useQuery } from '@finalytic/data';
import { SelectItem } from '@finalytic/ui';
import { Box } from '@mantine/core';
import { whereAccounts } from '@vrplatform/ui-common';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useExpenseForm } from '../useExpenseForm';

function useAccountQuery(search: string) {
  const [{ id: teamId }] = useTeam();

  return useInfiniteQuery(
    (q, { teamId, search }, { limit, offset }) => {
      const where: gqlV2.account_bool_exp = {
        ...whereAccounts({
          tenantId: teamId,
          search,
          classification: 'expense',
        }),
        type: { _eq: 'ledger' },
      };

      const list = q
        .accounts({
          where,
          order_by: [{ title: 'asc_nulls_last' }],
          limit,
          offset,
        })
        .map<SelectItem>((res) => ({
          value: res.id,
          label: res.title || 'No name',
        }));

      const aggregate = q.accountAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['listings'],
      variables: {
        teamId,
        search: search?.trim(),
      },
    }
  );
}

export const ExpenseLineAccount = ({ index }: { index: number }) => {
  const methods = useExpenseForm();
  const [search, setSearch] = useState('');

  const queryData = useAccountQuery(search);

  return (
    <Box>
      <Controller
        control={methods.control}
        name={`lines.${index}.accountId`}
        rules={{
          required: 'Account is required',
        }}
        render={({ field, fieldState }) => {
          const { data, isLoading: loading } = useQuery(
            (q, args) => {
              if (!args.value) return null;

              return q
                .accounts({
                  where: {
                    id: { _eq: args.value },
                  },
                })
                .map<SelectItem>((item) => ({
                  label: item.title || 'No name',
                  value: item.id!,
                }))[0];
            },
            {
              keepPreviousData: true,
              variables: {
                value: field.value,
              },
            }
          );

          const account = field.value ? data || null : null;

          return (
            <InputWrapper error={fieldState.error?.message} required>
              <InputSelect
                type="single"
                value={account}
                setValue={(v) => field.onChange(v?.value || null)}
                infiniteData={{ ...queryData, setSearch }}
                inputProps={{
                  error: !!fieldState.error,
                  placeholder:
                    queryData.data?.pages?.[0]?.list?.[0]?.label ||
                    'Select Account',
                  loadingQuery: loading,
                  // withClearButton: true,
                  width: 250,
                }}
                dropdownProps={{
                  width: 'target',
                }}
              />
            </InputWrapper>
          );
        }}
      />
    </Box>
  );
};
