import { CountBagde, Divider } from '@finalytic/components';
import {
  Query,
  useDashboard,
  useQuery,
  useTeam,
  useTeamId,
} from '@finalytic/data';
import { useSubscription } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { IconButton } from '@finalytic/ui';
import { groupBy } from '@finalytic/utils';
import { Box, Stack, Text, Tooltip } from '@mantine/core';
import { VRP_TENANT_ID, XIMPLIFI_TENANT_ID } from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useNavbarExpanded } from '../hooks';
import { NavbarRoute } from '../layout/navbar';
import { getMissingAccountAssignments } from '../queries';
import { PartnerConnectionErrorAggregateBadge } from '../views/partner-connections/PartnerConnectionErrorAggregateBadge';

function useTeamViews() {
  const [{ featureFlags, id: teamId }] = useTeam();

  const { data } = useQuery(
    (q, args) => {
      const views = q
        .views({
          where: {
            _or: [
              {
                tenantId: { _eq: args.teamId },
                type: { _eq: 'payments' },
                group: { _eq: 'payments' },
              },
              {
                tenantId: { _eq: args.teamId },
                group: { _eq: 'Reports' },
              },
              {
                isDefault: {
                  _eq: true,
                },
              },
            ],
          },
          order_by: [{ sortOrder: 'asc_nulls_last' }],
        })
        .map((view) => ({
          id: view.id,
          title: view.title || '',
          group: view.group,
          retoolViewId: view.retoolViewId,
          sortOrder: view.sortOrder,
        }));

      return {
        views,
      };
    },
    {
      queryKey: 'views',
      variables: {
        teamId,
      },
    }
  );

  const views = useMemo(
    () => groupBy(data?.views || [], (x) => x.group!),
    [data?.views]
  );

  const payViews = views?.payments?.map((i) => ({
    id: i.id as string,
    title: i.title,
    url: i.id,
    group: i.group as string,
  }));

  const reViews = views?.Reports?.map((i) => ({
    id: i.id!,
    title: i.title,
    url: i.retoolViewId!,
    group: i.group!.toLowerCase(),
  }));

  return {
    paymentViews: payViews || undefined,
    retoolViews: reViews || [],
    featureFlags,
  };
}

const iconSize = 18;

const NavigationSection = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  const { isDesktopExpanded } = useNavbarExpanded();

  return (
    <Box>
      {title && isDesktopExpanded && (
        <Text
          component="span"
          mb="xs"
          color="white"
          pl={5}
          sx={{ opacity: 0.5, display: 'block' }}
        >
          {title}
        </Text>
      )}
      <Stack gap="xs">{children}</Stack>
    </Box>
  );
};

export function PartnerNavigation() {
  const { paymentViews: _, retoolViews, featureFlags } = useTeamViews();
  // LEGACY
  const [dashboard] = useDashboard();
  const [{ propertyManagementSystems, partnerId, id: teamId }] = useTeam();
  const showMappingsV2 =
    (featureFlags?.includes('mappingsV2') ||
      partnerId === XIMPLIFI_TENANT_ID) &&
    !propertyManagementSystems.length;
  const reViews = retoolViews.filter(
    (x) => !showMappingsV2 || x.url !== '11ef2837-7612-48db-a7c7-68c621d41efc'
  );
  const goto = useNavigate();

  const isXimplifiTeam = teamId === XIMPLIFI_TENANT_ID;
  const isPartnerDashboard = dashboard === 'partner';
  const isVrpTeam = teamId === VRP_TENANT_ID;

  return (
    <NavigationSection
      title={!['partner', 'overview'].includes(dashboard) ? 'Legacy' : ''}
    >
      <NavbarRoute
        title="Overview"
        link={'/dashboard'}
        icon={<Icon icon="Activity2Icon" size={iconSize} />}
      />
      {isPartnerDashboard && (
        <NavbarRoute
          title="Your Team"
          link={'/members'}
          icon={<Icon icon="UsersIcon" size={iconSize} />}
        />
      )}

      <Box my="sm">
        <NavbarRoute
          title="Statements"
          link={'/team-statements'}
          icon={<Icon icon="ClipboardTextIcon" size={iconSize} />}
        />
      </Box>
      <Stack gap="xs" mb="sm">
        <NavbarRoute
          title="Automations"
          link={'/team-automations'}
          icon={<Icon icon="RocketIcon" size={iconSize} />}
        />

        <NavbarRoute
          title="Connections"
          link={'/team-connections?status=active'}
          icon={<Icon icon="ShareIcon" size={iconSize} />}
          notification={
            <IconButton
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                goto('/team-connections/errors?status=active');
              }}
              sx={{
                width: 'unset',
              }}
            >
              <PartnerConnectionErrorAggregateBadge loadingColor="white" />
            </IconButton>
          }
        />
      </Stack>

      {isXimplifiTeam && isPartnerDashboard && (
        <NavbarRoute
          title="Line Master List"
          link={'/line-master-list'}
          icon={<Icon icon="ListUnorderedIcon" size={iconSize} />}
          notification={<LineMasterIssueBadge />}
        />
      )}

      {isVrpTeam && isPartnerDashboard && (
        <>
          <Divider opacity={0.2} mb="lg" />
          <NavbarRoute
            title="Chart of Accounts"
            link={'/accounts'}
            icon={<Icon icon="ArchiveIcon" size={iconSize} />}
            notification={<ChartOfAccountIssueBadge />}
          />
          <NavbarRoute
            title="VRP Billing"
            link={'/billing'}
            icon={<Icon icon="CircleDollarIcon" size={iconSize} />}
          />
        </>
      )}

      {/* LEGACY */}
      {showMappingsV2 && isPartnerDashboard && (
        <NavbarRoute
          title="Mappings"
          link="/reports/11ef2837-7612-48db-a7c7-68c621d41efc"
          icon={<Icon icon="FileTextIcon" size={iconSize} />}
        />
      )}
      {reViews?.length > 0 && isPartnerDashboard && (
        <NavbarRoute
          title="Reports"
          link={`/${retoolViews[0].group}/${retoolViews[0].url}`}
          icon={<Icon icon="FileTextIcon" size={iconSize} />}
          subRoutes={retoolViews}
        />
      )}
    </NavigationSection>
  );
}
const LineMasterIssueBadge = () => {
  const [{ name: partnerName }] = useTeam();

  const { data, isLoading } = useSubscription(
    (q) => {
      const count =
        q
          .paymentLineClassificationAggregate({
            where: {
              _not: {
                settings: {
                  value: { _is_null: false },
                  key: { _eq: 'accountingType' },
                  parentSettingId: { _is_null: true },
                },
              },
            },
          })
          .aggregate?.count() || 0;

      return count;
    },
    { partnerName: partnerName.toLowerCase() }
  );

  return (
    <CountBagde count={data || 0} loading={isLoading} loadingColor="white" />
  );
};

const ChartOfAccountIssueBadge = () => {
  const [teamId] = useTeamId();

  const { data, isLoading } = useSubscription(
    (q, args) => {
      const count = getMissingAccountAssignments(q as Query, {
        teamId: args.teamId,
      }).length;

      return count;
    },
    { teamId }
  );

  const count = data || 0;

  return (
    <Tooltip
      label={`${count} missing account assignments`}
      withArrow
      disabled={!count}
    >
      <CountBagde count={count} loading={isLoading} loadingColor="white" />
    </Tooltip>
  );
};
