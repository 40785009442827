import { Button, InputAmount, InputSelect } from '@finalytic/components';
import { taxBehavior_enum } from '@finalytic/graphql';
import { Icon } from '@finalytic/icons';
import { LoadingIndicator } from '@finalytic/ui';
import { bankersRound, formatCurrency, toTitleCase } from '@finalytic/utils';
import {
  Box,
  Checkbox,
  Collapse,
  Group,
  HoverCard,
  InputBase,
  InputWrapper as MantineInputWrapper,
  Popover,
  Stack,
  Text,
  rem,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { formatPercentage } from '@vrplatform/ui-common';
import { PropsWithChildren, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTaxRatesQuery } from '../../../../queries';
import { TaxRateModal } from '../../../settings/tax-rates/TaxRateModal';
import { useExpenseForm } from '../useExpenseForm';

type Props = {
  index: number;
};

export const ExpenseAmountInput = ({ index }: Props) => {
  const [opened, handlers] = useDisclosure(false);
  const [openedAddTaxRate, handlersAddTaxRate] = useDisclosure(false);

  const parentMethods = useExpenseForm();

  const currency = useWatch({
    control: parentMethods.control,
    name: 'currency',
  });

  const line = useWatch({
    control: parentMethods.control,
    name: `lines.${index}`,
  });

  const showMarkup = line.party === 'owners';

  type FormInputs = {
    centTotal: number;
    markupCentTotal: number;
    rateId: string | null;
    rateBehavior: taxBehavior_enum;
    // taxCentTotal: number;
  };

  const methods = useForm<FormInputs>({
    values: line
      ? {
          centTotal: line.centTotal,
          markupCentTotal: line.markup || 0,
          rateId: line.rateId || null,
          rateBehavior: line.rateBehavior || 'included',
        }
      : undefined,
  });

  const { data: rates, error, isLoading: loadingRates } = useTaxRatesQuery();

  const rateId = useWatch({
    control: methods.control,
    name: 'rateId',
    defaultValue: line?.rateId,
  });

  const rateBehavior = useWatch({
    control: methods.control,
    name: 'rateBehavior',
    defaultValue: line?.rateBehavior || 'included',
  });

  const showTaxIncludedCheckbox = !!rateId;
  const rate = rateId ? rates?.find((x) => x.value === rateId) : undefined;

  const submitPopover = (data: FormInputs) => {
    parentMethods.setValue(`lines.${index}.centTotal`, data.centTotal);
    parentMethods.setValue(`lines.${index}.markup`, data.markupCentTotal);
    parentMethods.setValue(`lines.${index}.rateId`, data.rateId);
    parentMethods.setValue(`lines.${index}.rateBehavior`, data.rateBehavior);
    handlers.close();
  };

  const resetPopover = () => {
    parentMethods.setValue(`lines.${index}.centTotal`, 0);
    parentMethods.setValue(`lines.${index}.markup`, 0);
    methods.reset(
      {
        centTotal: 0,
        markupCentTotal: 0,
      },
      {
        keepDirtyValues: false,
        keepErrors: false,
        keepDirty: false,
        keepValues: false,
      }
    );
    handlers.close();
  };

  const closePopover = () => {
    handlers.close();
    methods.reset({
      centTotal: line.centTotal,
      markupCentTotal: line.markup,
      rateBehavior: line.rateBehavior || 'included',
      rateId: line.rateId || null,
    });
  };

  const expenseLineTotal = useMemo(() => {
    const centTotal = line.centTotal ?? 0;
    const appliedAmount =
      rate && rateBehavior === 'excluded'
        ? bankersRound(centTotal + centTotal * rate.percentage)
        : centTotal;

    const markup = showMarkup ? line?.markup || 0 : 0;

    return (appliedAmount + markup) / 100;
  }, [line?.centTotal, line?.markup, rate, rateBehavior, showMarkup]);

  return (
    <>
      <TaxRateModal
        closeModal={handlersAddTaxRate.close}
        opened={openedAddTaxRate}
        zIndex={201}
      />
      <Popover
        position="top-end"
        opened={opened}
        onClose={handlers.close}
        closeOnClickOutside={false}
        closeOnEscape={false}
        shadow="md"
        width={400}
        withArrow
        arrowOffset={10}
        arrowSize={15}
      >
        <Popover.Target>
          <Box>
            <HoverCard
              disabled={opened}
              position="bottom-end"
              withArrow
              withinPortal
              width={250}
              shadow="md"
              radius="md"
              arrowOffset={10}
            >
              <HoverCard.Target>
                <InputBase
                  value={formatCurrency(expenseLineTotal, currency)}
                  type="button"
                  onClick={handlers.open}
                  pointer
                  sx={(theme) => ({
                    width: 100,
                    input: {
                      textAlign: 'right',
                      '&:focus': {
                        boxShadow: `0px 0px 0px 2px ${
                          theme.colors[theme.primaryColor][4]
                        }40`,
                        borderColor: theme.colors[theme.primaryColor][6],
                      },
                    },
                  })}
                />
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Stack gap="xs">
                  <Group wrap="nowrap" align="flex-start">
                    <Text c="gray" flex={1}>
                      Applied amount
                    </Text>
                    <Text ta="right" fw={500}>
                      {formatCurrency(line.centTotal / 100, currency)}
                    </Text>
                  </Group>
                  {'rateId' in line && !!line.rateId && (
                    <Group wrap="nowrap" align="flex-start">
                      <Text c="gray" flex={1}>
                        Tax Rate{' '}
                        <Text span size="xs" c="gray">
                          ({toTitleCase(line.rateBehavior)})
                        </Text>
                      </Text>
                      {loadingRates ? (
                        <LoadingIndicator size="xs" />
                      ) : (
                        <Text ta="right" fw={500}>
                          {formatPercentage(
                            rates?.find((x) => x.value === line.rateId)
                              ?.percentage || 0
                          )}
                        </Text>
                      )}
                    </Group>
                  )}
                  {showMarkup && (
                    <Group wrap="nowrap" align="flex-start">
                      <Text c="gray" flex={1}>
                        Markup
                      </Text>
                      <Text ta="right" fw={500}>
                        {formatCurrency((line.markup || 0) / 100, currency)}
                      </Text>
                    </Group>
                  )}
                </Stack>
              </HoverCard.Dropdown>
            </HoverCard>
          </Box>
        </Popover.Target>
        <Popover.Dropdown>
          <Stack gap="xs">
            <InputWrapper title="Applied amount">
              <Controller
                control={methods.control}
                name={'centTotal'}
                rules={{
                  validate: (value) => {
                    if (typeof value !== 'number') return 'Amount is required';
                    if (value <= 0) return 'Please enter a positive amount';
                    return true;
                  },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <InputAmount
                      value={field.value / 100}
                      setValue={(value) =>
                        field.onChange(Number(value || 0) * 100)
                      }
                      error={fieldState.error?.message}
                      placeholder="0.00"
                      decimalScale={2}
                      hideControls
                      w={150}
                    />
                  );
                }}
              />
            </InputWrapper>

            {showMarkup && (
              <InputWrapper title="Markup">
                <Controller
                  control={methods.control}
                  name={'markupCentTotal'}
                  render={({ field, fieldState }) => {
                    return (
                      <InputAmount
                        value={field.value / 100}
                        setValue={(value) =>
                          field.onChange(Number(value || 0) * 100)
                        }
                        error={fieldState.error?.message}
                        placeholder="0.00"
                        decimalScale={2}
                        hideControls
                        w={150}
                      />
                    );
                  }}
                />
              </InputWrapper>
            )}

            <InputWrapper title="Tax Rate">
              <Controller
                control={methods.control}
                name={'rateId'}
                defaultValue={line?.rateId}
                render={({ field, fieldState }) => {
                  const value =
                    rates?.find((x) => x.value === field.value) || null;

                  return (
                    <MantineInputWrapper error={fieldState.error?.message}>
                      <InputSelect
                        type="single"
                        value={value}
                        setValue={(value) => {
                          field.onChange(value?.value);
                          const behavior = methods.getValues('rateBehavior');
                          if (
                            (!behavior || behavior === 'excluded') &&
                            value?.value
                          ) {
                            methods.setValue('rateBehavior', 'included', {
                              shouldDirty: true,
                              shouldTouch: true,
                              shouldValidate: true,
                            });
                          }
                        }}
                        inputProps={{
                          loadingQuery: loadingRates,
                          error: !!fieldState.error,
                          width: 250,
                          placeholder: rates?.[0]?.label || 'Select tax rate',
                          withClearButton: true,
                        }}
                        customBottomActions={[
                          {
                            id: 'add-tax-rate',
                            label: 'Other Rate - Add tax rate',
                            description:
                              'If you are missing a tax rate, add it now.',
                            onSubmit: () => handlersAddTaxRate.open(),
                            icon: <Icon icon="PlusIcon" size={16} />,
                          },
                        ]}
                        data={{
                          options: rates || [],
                          error,
                          loading: loadingRates,
                        }}
                      />
                    </MantineInputWrapper>
                  );
                }}
              />
            </InputWrapper>

            <Collapse in={showTaxIncludedCheckbox}>
              <InputWrapper title="">
                <Controller
                  control={methods.control}
                  name={'rateBehavior'}
                  defaultValue={line?.rateBehavior || 'included'}
                  render={({ field, fieldState }) => {
                    return (
                      <MantineInputWrapper error={fieldState.error?.message}>
                        <Checkbox
                          checked={field.value === 'included'}
                          label="Include tax in applied amount"
                          onChange={(event) =>
                            field.onChange(
                              event.currentTarget.checked
                                ? 'included'
                                : 'excluded'
                            )
                          }
                          size="xs"
                          sx={(theme) => ({
                            flex: 1,
                            width: 250,
                            color: theme.colors.neutral[8],
                          })}
                        />
                      </MantineInputWrapper>
                    );
                  }}
                />
              </InputWrapper>
            </Collapse>
          </Stack>

          <Group gap="xs" justify="flex-end" mt="lg">
            <Button size="xs" onClick={closePopover}>
              Cancel
            </Button>
            <Button size="xs" leftIcon={'RefreshCwIcon'} onClick={resetPopover}>
              Reset
            </Button>
            <Button
              size="xs"
              variant="primary"
              leftIcon={'CheckIcon'}
              onClick={methods.handleSubmit(submitPopover)}
            >
              Save
            </Button>
          </Group>
        </Popover.Dropdown>
      </Popover>
    </>
  );
};

const InputWrapper = ({
  title,
  children,
}: PropsWithChildren<{ title: string }>) => {
  return (
    <Group wrap="nowrap" align="flex-start">
      <Text fw={500} flex={1} mt={rem(8)}>
        {title}
      </Text>
      <Box>{children}</Box>
    </Group>
  );
};

// Applied amount
// Merchant amount
// Tax type
// Tax amount
// adjustment type
// description
// reset + cancel + save button
