import { IconButton, Select } from '@finalytic/components';
import { gqlV2, useMutation, useQuery } from '@finalytic/data';
import { currency_enum } from '@finalytic/graphql';
import { ChevronIcon } from '@finalytic/icons';
import { LoadingIndicator, SelectItem } from '@finalytic/ui';
import { Maybe, sortBy, toTitleCase } from '@finalytic/utils';
import { Group, Text } from '@mantine/core';
import { Box, useMantineTheme } from '@mantine/core';
import { currencies } from '@vrplatform/ui-common';
import { memo, useMemo, useState } from 'react';

type Props = {
  id: string;
  currency: Maybe<gqlV2.currency_enum>;
};

export const ListingCurrencySelect = memo<Props>(function Cell({
  currency: initialCurrency,
  id,
}) {
  const [updatedCurrency, setUpdatedCurrency] =
    useState<Maybe<gqlV2.currency_enum>>();

  const { mutate, loading: loadingMutation } = useMutation(
    (
      q,
      args: {
        id: string;
        currency: gqlV2.currency_enum | null;
      }
    ) => {
      return q.updateListing({
        pk_columns: {
          id: args.id,
        },
        _set: {
          defaultCurrency: args.currency,
        },
      })?.defaultCurrency;
    }
  );

  const updateCurrency = async (currency: currency_enum | null) =>
    mutate({
      args: {
        id,
        currency,
      },
    }).then(setUpdatedCurrency);

  const {
    data,
    isLoading: loadingQuery,
    error,
  } = useQuery(
    (q, args) => {
      const currencies = args.availableCurrencies;

      const c = q
        .currency({
          order_by: [{ name: 'asc_nulls_last' }],
        })
        .map<SelectItem<gqlV2.currency_enum>>((item) => ({
          label:
            currencies[
              (item.name?.toUpperCase() || '') as keyof typeof currencies
            ]?.currencyName ||
            toTitleCase(item.name) ||
            '',
          value: item.name as currency_enum,
        }));

      return sortBy(c, 'label', 'asc');
    },
    {
      variables: {
        availableCurrencies: currencies,
      },
    }
  );

  const { options, pinned } = useMemo(() => {
    const opts = data || [];

    const pinned = ['usd', 'eur', 'gbp'];

    return {
      options: opts.filter((o) => !pinned.includes(o.value)),
      pinned: opts.filter((o) => pinned.includes(o.value)),
    };
  }, [data]);

  const value =
    [...options, ...pinned]?.find((o) => {
      if (updatedCurrency !== undefined) return o.value === updatedCurrency;
      return o.value === initialCurrency;
    }) || null;

  return (
    <Box>
      <Select
        type="single"
        value={value}
        setValue={(v) => updateCurrency(v?.value || null)}
        data={{
          options,
          loading: loadingQuery,
          error,
          sort: null,
        }}
        inputProps={{
          placeholder: options[0]?.label || 'Select type',
          loadingQuery,
          loadingMutation,
          withClearButton: true,
        }}
        dropdownProps={{
          width: 'target',
        }}
        pinnedItems={pinned}
      >
        {({ value, type, opened, loadingMutation, withClearButton }) => {
          const { primaryColor, colors, radius, spacing } = useMantineTheme();

          if (type !== 'single') return <div />;

          return (
            <Group
              wrap="nowrap"
              gap={8}
              display="inline-flex"
              sx={{
                cursor: 'pointer',
                border: '1px solid',
                borderColor: opened ? colors[primaryColor][6] : 'transparent',
                borderRadius: radius.sm,
                boxShadow: opened
                  ? `0px 0px 0px 2px ${colors[primaryColor][4]}40`
                  : undefined,
                color: colors[primaryColor][7],
                padding: `2px ${spacing.sm}`,
              }}
            >
              <Text lineClamp={1}>{value?.label || 'Auto'}</Text>
              {loadingMutation ? (
                <LoadingIndicator size={'0.75rem'} />
              ) : withClearButton && value?.value ? (
                <IconButton
                  icon="CrossIcon"
                  size={16}
                  color={colors[primaryColor][7]}
                  onClick={() =>
                    updateCurrency(null).then(() => setUpdatedCurrency(null))
                  }
                />
              ) : (
                <ChevronIcon color={colors[primaryColor][7]} size={16} />
              )}
            </Group>
          );
        }}
      </Select>
    </Box>
  );
});
