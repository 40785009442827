import { AlertTriangleIcon } from '@finalytic/icons';
import {
  Card,
  Center,
  Group,
  MantineSize,
  Text,
  rem,
  useMantineColorScheme,
} from '@mantine/core';
import { ReactNode } from 'react';

type Props = {
  rows: LineProps[];
  mb?: MantineSize | 0;
  p?: MantineSize | 0;
  bg?: string;
};

type Styling = {
  labelWidth?: number;
};

export const DrawerInfoCard = ({
  rows,
  mb = 'sm',
  p,
  bg,
  ...styles
}: Props & Styling) => {
  const { colorScheme } = useMantineColorScheme();

  if (rows.every((row) => !row.text)) return null;

  return (
    <Card
      sx={(theme) => ({
        backgroundColor:
          bg ??
          (colorScheme === 'dark'
            ? theme.colors.dark[6]
            : theme.colors.neutral[1]),
        display: 'flex',
        flexDirection: 'column',
        gap: rem(14),
        flexShrink: 0,
      })}
      radius="md"
      mb={mb}
      p={p}
    >
      {rows.map((row, index) => (
        <Line key={index} {...row} {...styles} />
      ))}
    </Card>
  );
};

type LineProps = {
  icon: typeof AlertTriangleIcon | null;
  title: string;
  text: ReactNode;
};

const Line = ({
  text,
  icon: Icon,
  title,
  labelWidth = 100,
}: LineProps & Styling) => {
  const { colorScheme } = useMantineColorScheme();

  if (!text) return null;

  return (
    <Group wrap="nowrap" gap="xs">
      {Icon && (
        <Center pb={2} sx={{ flexShrink: 0, width: 20 }}>
          <Icon size={18} />
        </Center>
      )}
      <Text
        component="span"
        // sx={{ flex: 0.5 }}
        w={labelWidth}
        size="sm"
        c={colorScheme === 'dark' ? 'dimmed' : 'neutral'}
      >
        {title}
      </Text>
      <Text size="sm" component="span" sx={{ flex: 1 }}>
        {text}
      </Text>
    </Group>
  );
};
