import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import { Edit3Icon, Icon } from '@finalytic/icons';
import {
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
  showSuccessNotification,
} from '@finalytic/ui';
import { useNavigate } from 'react-router';
import { useJournalEntriesModal } from '../../modals';
import { ExpenseRow } from '../../views/expenses/list/useExpenseTableQuery';

export const ExpenseEllipsisMenuItems = ({
  expense,
  openDeleteModal,
  openPaymentStatusModal,
}: {
  expense: ExpenseRow;
  openDeleteModal: () => void;
  openPaymentStatusModal: () => void;
}) => {
  const goto = useNavigate();

  const { open: openJournalEntriesModal } = useJournalEntriesModal();

  return (
    <>
      <EllipsisMenuItem
        onClick={() => goto(`/expense/${expense.id}/edit`)}
        customIcon={<Edit3Icon size={16} />}
      >
        Edit
      </EllipsisMenuItem>

      <EllipsisMenuItem
        onClick={openPaymentStatusModal}
        customIcon={<Icon icon="CircleDollarIcon" size={16} />}
      >
        Mark as {expense.paidStatus === 'paid' ? 'unpaid' : 'paid'}
      </EllipsisMenuItem>

      <EllipsisMenuDivider />

      <EllipsisMenuDangerItem onClick={openDeleteModal}>
        Delete
      </EllipsisMenuDangerItem>
      <HiddenFeatureIndicator permission="super-admin">
        <EllipsisMenuItem
          customIcon={<Icon icon="ListUnorderedIcon" size={16} />}
          onClick={() =>
            openJournalEntriesModal({
              transactionId: {
                _eq: expense.id,
              },
            })
          }
        >
          Journal Entries
        </EllipsisMenuItem>

        <EllipsisMenuItem
          customIcon={
            <Icon
              icon="CopyIcon"
              size={16}
              color={(theme) => theme.colors.neutral[5]}
            />
          }
          disabled={!expense.id}
          onClick={() => {
            navigator.clipboard.writeText(`${expense.id}`).then(() =>
              showSuccessNotification({
                message: 'Expense ID was added to your clipboard.',
              })
            );
          }}
        >
          Copy ID
        </EllipsisMenuItem>
      </HiddenFeatureIndicator>
    </>
  );
};
