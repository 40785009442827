import {
  AddressFormInputs,
  Button,
  Input,
  InputSelect,
  InputWrapper,
} from '@finalytic/components';
import { useMutation, useTeamId } from '@finalytic/data';
import { contact_insert_input } from '@finalytic/graphql';
import { AlertTriangleIcon, OfficeIcon, UserIcon } from '@finalytic/icons';
import { Drawer, showErrorNotification } from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import {
  Box,
  Checkbox,
  Divider,
  Group,
  ScrollArea,
  Stack,
  rem,
} from '@mantine/core';
import {
  OWNER_COMPANY_TYPES,
  OwnerCompanyTypeValue,
  formatAddress,
  getCountryByIsoCode,
  getStateByIsoCode,
  validateTin,
} from '@vrplatform/ui-common';
import { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { DrawerHeader, DrawerSectionTitle } from '../_components';

type Props = {
  opened: boolean;
  closeDrawer: () => void;
  contact?: FormInputs;
};

type FormInputs = {
  id: Maybe<string>;
  firstName: string;
  name: string;
  tenantId: string;
  email: string;
  phone: string;
  taxId: string;
  address: Parameters<typeof formatAddress>[0] | null | undefined;
  companyType: 'individual' | OwnerCompanyTypeValue;
};

export const ContactDrawer = ({ closeDrawer, opened, contact }: Props) => {
  const [teamId] = useTeamId();

  const [taxDisabled, setTaxDisabled] = useState(false);

  const methods = useForm<FormInputs>({
    values: contact,
  });

  const ownerType = methods.watch('companyType') || 'individual';
  const isIndividual = ownerType === 'individual';

  const { mutate } = useMutation(
    (q, input: contact_insert_input) => {
      if (input.id) {
        return q.updateContact({
          pk_columns: {
            id: input.id,
          },
          _set: input,
        }).id as string;
      }

      return q.insertContact({
        object: input,
      }).id as string;
    },
    {
      invalidateQueryKeys: ['contacts'],
    }
  );

  const submit = async (data: FormInputs) => {
    const hasAddress = !!data.address?.line1?.trim();

    if (
      hasAddress &&
      (!data.address?.stateCode || !data.address?.countryCode)
    ) {
      return showErrorNotification({
        title: 'Validation error',
        message: 'Please select a country and state.',
        icon: <AlertTriangleIcon size={20} />,
      });
    }

    const country =
      (data.address?.countryCode &&
        getCountryByIsoCode(data.address?.countryCode)?.name) ||
      '';
    const state =
      (data.address?.countryCode &&
        data.address?.stateCode &&
        getStateByIsoCode(data.address?.stateCode, data.address?.countryCode)
          ?.name) ||
      '';

    await mutate({
      args: {
        id: contact?.id,
        tenantId: teamId,
        firstName: data.firstName.trim(),
        name: data.name.trim(),
        email: data.email?.toLowerCase().trim(),
        phone: data.phone?.trim(),
        taxId: data.taxId?.trim(),
        companyType:
          data.companyType === 'individual' ? null : data.companyType,
        type: 'vendor',
        addressData: hasAddress
          ? {
              city: data.address!.city.trim(),
              line1: data.address!.line1.trim(),
              line2: data.address!.line2?.trim(),
              stateCode: data.address!.stateCode,
              state,
              countryCode: data.address!.countryCode,
              country,
              postcode: data.address!.postcode.trim(),
            }
          : null,
      },
    })
      .then(closeDrawer)
      .catch((err) => console.error(err));
  };

  return (
    <Drawer
      opened={opened}
      onClose={closeDrawer}
      containerSx={{
        paddingInline: 0,
      }}
    >
      <DrawerHeader
        title={contact ? 'Edit Contact' : 'Add Contact'}
        closeDrawer={closeDrawer}
        containerSx={{
          paddingBottom: 0,
        }}
      />
      <Divider
        sx={(theme) => ({
          // marginInline: `-${theme.spacing.xs}`,
          borderTopColor: theme.colors.gray[2],
        })}
      />
      <FormProvider {...methods}>
        <Box
          sx={() => ({
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            overflowY: 'hidden',
            overflowX: 'unset',
          })}
        >
          <Box>
            <Controller
              control={methods.control}
              defaultValue="individual"
              name="companyType"
              rules={{
                required: 'Type is required',
              }}
              render={({ field, fieldState: { error } }) => {
                const individualOption = {
                  label: 'Individual',
                  value: 'individual',
                  icon: <UserIcon size={16} />,
                };

                const options = [
                  individualOption,
                  ...OWNER_COMPANY_TYPES.map((x) => ({
                    ...x,
                    icon: <OfficeIcon size={16} />,
                  })),
                ];

                return (
                  <Box px="sm" pt="xs" pb={rem(4)}>
                    <InputWrapper label="Type" required error={error?.message}>
                      <InputSelect
                        data={{
                          options,
                          sort: null,
                        }}
                        type="single"
                        value={
                          options.find((x) => x.value === field.value) || null
                        }
                        inputProps={{
                          placeholder: 'Company type',
                          error: !!error,
                        }}
                        dropdownProps={{ width: 'target' }}
                        setValue={(e) => {
                          if (e?.value) {
                            if (
                              (e.value === 'individual' &&
                                field.value !== 'individual') ||
                              (e.value !== 'individual' &&
                                field.value === 'individual')
                            ) {
                              methods.setValue('firstName', '');
                              methods.setValue('name', '');
                              methods.clearErrors('firstName');
                              methods.clearErrors('name');
                            }
                            field.onChange(e.value);
                          }
                        }}
                      />
                    </InputWrapper>
                  </Box>
                );
              }}
            />
            <Divider mt="xs" />
          </Box>

          <ScrollArea
            styles={(theme) => ({
              root: {
                flex: 1,
              },
              viewport: {
                paddingInline: theme.spacing.sm,
              },
            })}
          >
            <Stack mt="sm">
              {!isIndividual ? (
                <Controller
                  control={methods.control}
                  name="name"
                  defaultValue=""
                  rules={{
                    required: 'Company name is required',
                    validate: trapSpacesForRequiredFields,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <InputWrapper
                      label="Company name"
                      error={error?.message}
                      required
                    >
                      <Input
                        {...field}
                        placeholder="Company name"
                        autoComplete="off"
                        error={!!error}
                      />
                    </InputWrapper>
                  )}
                />
              ) : (
                <>
                  <Box
                    sx={(theme) => ({
                      alignItems: 'flex-start',
                      justifyContent: 'stretch',
                      display: 'flex',
                      gap: theme.spacing.md,
                      '> div': {
                        flex: 1,
                      },
                    })}
                  >
                    <Controller
                      control={methods.control}
                      name="firstName"
                      defaultValue=""
                      rules={{
                        required: 'First name is required',
                        validate: trapSpacesForRequiredFields,
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <InputWrapper
                          label="First name"
                          error={error?.message}
                          required={true}
                        >
                          <Input
                            {...field}
                            placeholder="First name"
                            autoComplete="off"
                            error={!!error}
                          />
                        </InputWrapper>
                      )}
                    />
                    <Controller
                      control={methods.control}
                      name="name"
                      defaultValue=""
                      rules={{
                        required: 'Last name is required',
                        validate: trapSpacesForRequiredFields,
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <InputWrapper
                          label="Last name"
                          error={error?.message}
                          required={true}
                        >
                          <Input
                            {...field}
                            placeholder="Last name"
                            autoComplete="off"
                            error={!!error}
                          />
                        </InputWrapper>
                      )}
                    />
                  </Box>
                </>
              )}

              <Controller
                control={methods.control}
                name="email"
                rules={{
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: 'Please enter a valid email address',
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <InputWrapper label="Email" error={error?.message}>
                    <Input
                      {...field}
                      placeholder="Email"
                      autoComplete="off"
                      type="email"
                      error={!!error}
                    />
                  </InputWrapper>
                )}
              />

              <Controller
                control={methods.control}
                name="phone"
                render={({ field, fieldState: { error } }) => (
                  <InputWrapper label="Phone" error={error?.message}>
                    <Input
                      {...field}
                      ref={undefined}
                      placeholder="Phone"
                      type="tel"
                      autoComplete="off"
                      error={!!error}
                    />
                  </InputWrapper>
                )}
              />
            </Stack>

            <DrawerSectionTitle>Address</DrawerSectionTitle>

            <AddressFormInputs />

            <DrawerSectionTitle>Tax Information</DrawerSectionTitle>

            <Controller
              control={methods.control}
              name="taxId"
              rules={{
                required: taxDisabled
                  ? undefined
                  : 'Tax Identification Number is required',
                validate: taxDisabled ? undefined : (v) => validateTin(v),
              }}
              render={({ field, fieldState: { error } }) => (
                <InputWrapper
                  label="TIN (US only)"
                  error={error?.message}
                  mb="sm"
                  required={!taxDisabled}
                >
                  <Input
                    {...field}
                    placeholder="Tax Identification Number"
                    autoComplete="off"
                    error={!!error}
                    disabled={taxDisabled}
                  />
                </InputWrapper>
              )}
            />

            <Checkbox
              label="I do not have a TIN"
              size="xs"
              mb="sm"
              data-testid="no-tin-checkbox"
              checked={taxDisabled}
              onChange={() => {
                setTaxDisabled((e) => !e);
                methods.clearErrors('taxId');
                methods.setValue('taxId', '');
              }}
            />
          </ScrollArea>

          <Box
            sx={(theme) => ({
              paddingBlock: theme.spacing.sm,
              borderTop: `1px solid ${theme.colors.gray[2]}`,
              paddingInline: theme.spacing.sm,
            })}
          >
            <Group>
              <Button
                onClick={() => closeDrawer()}
                disabled={methods.formState.isSubmitting}
              >
                Cancel
              </Button>
              <Button
                sx={{ flexGrow: '1!important' as any }}
                type="submit"
                disabled={!methods.formState.isDirty}
                onClick={methods.handleSubmit(submit)}
                variant="primary"
                loading={methods.formState.isSubmitting}
              >
                Submit
              </Button>
            </Group>
          </Box>
        </Box>
      </FormProvider>
    </Drawer>
  );
};

const trapSpacesForRequiredFields = (value: string) =>
  !!value.trim() || 'This field is required';
