import { Filter } from '@finalytic/components';
import {
  gqlV2,
  useDashboard,
  useEnabledFeatures,
  useInfiniteQuery,
  useMe,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import { account_bool_exp, paidStatus_enum } from '@finalytic/graphql';
import {
  ArrayParam,
  SelectItem,
  StringParam,
  useQueryParams,
} from '@finalytic/ui';
import { day, hasValue, isUUID } from '@finalytic/utils';
import { Box, Group } from '@mantine/core';
import {
  getListingName,
  orderByAccount,
  whereAccounts,
  whereListings,
} from '@vrplatform/ui-common';
import { useMemo, useState } from 'react';

export const useExpenseTableFilter = () => {
  const [filter, setFilter] = useQueryParams({
    search: StringParam,
    listingIds: ArrayParam,
    date: StringParam,
    accountId: StringParam,
    paidStatus: StringParam,
  });

  return {
    filter,
    setFilter,
    reset: () => {
      const initial = {
        search: undefined,
        type: undefined,
        listingIds: undefined,
        date: undefined,
        accountId: undefined,
        paidStatus: undefined,
      };

      setFilter(initial);
    },
  };
};

export const ExpenseTableFilter = () => {
  const { filter, setFilter } = useExpenseTableFilter();

  return (
    <Group>
      <Filter.Search
        value={filter.search || ''}
        setValue={(v) => setFilter({ search: v })}
      />
      <DateFilter />
      <PaidStatusFilter />
      <AccountFilter />
      <ListingFilter />
    </Group>
  );
};

const DateFilter = () => {
  const { filter, setFilter } = useExpenseTableFilter();

  return (
    <Filter.Date
      value={filter.date || undefined}
      setValue={(value) => {
        setFilter({
          date: value
            ?.filter(hasValue)
            .map((date) => day(date).yyyymmdd())
            .join('...'),
        });
      }}
    />
  );
};

const ListingFilter = () => {
  const [teamId] = useTeamId();
  const [dashboard] = useDashboard();
  const { filter, setFilter } = useExpenseTableFilter();
  const { id: meId } = useMe();
  const [search, setSearch] = useState('');
  const { GL } = useEnabledFeatures();

  const queryData = useInfiniteQuery(
    (q, { teamId, dashboard, search, meId, GL }, { limit, offset }) => {
      const where: gqlV2.listing_bool_exp = whereListings({
        currentTeamId: teamId,
        dashboard,
        partnerTeamIds: [],
        search,
        meId,
        GL,
      });

      const list = q
        .listings({
          where,
          order_by: [{ calculated_title: 'asc_nulls_last' }],
          limit,
          offset,
        })
        .map<SelectItem>((res) => ({
          value: res.id,
          label: getListingName(res),
        }));

      const aggregate = q.listingAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['reservations', 'listings'],
      variables: {
        teamId,
        search: search?.trim(),
        dashboard,
        meId,
        GL,
      },
    }
  );

  const { data } = useQuery(
    (q, { listingIds }) => {
      const value = q
        .listings({ where: { id: { _in: listingIds } } })
        .map<SelectItem>((item) => ({
          label: getListingName(item),
          value: item.id,
        }));

      return {
        value,
      };
    },
    {
      skip: !teamId,
      queryKey: ['reservations', 'listings'],
      keepPreviousData: true,
      variables: {
        listingIds: filter.listingIds || [],
      },
    }
  );

  const value = data?.value || [];

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ listingIds: v.map((i) => i.value) })}
      label="Listing"
      type="multiple"
      withinPortal
      infiniteData={{ ...queryData, setSearch }}
    />
  );
};

const AccountFilter = () => {
  const [teamId] = useTeamId();
  const { filter, setFilter } = useExpenseTableFilter();
  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, { teamId, search }, { limit, offset }) => {
      const where: account_bool_exp = whereAccounts({
        tenantId: teamId,
        search,
        type: 'bank',
      });

      const list = q
        .accounts({
          where,
          order_by: orderByAccount,
          limit,
          offset,
        })
        .map<SelectItem>((account) => ({
          label: account.title || 'No name',
          value: account.id,
        }));

      const aggregate =
        q
          .accountAggregate({
            where,
          })
          ?.aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['accounts'],
      variables: {
        teamId,
        search: search?.trim(),
      },
    }
  );

  const { data } = useQuery(
    (q, args) => {
      if (args.accountId === 'non-trust')
        return {
          label: 'Non-Trust Account',
          value: 'non-trust',
        };

      if (!args.accountId || !isUUID(args.accountId)) return null;

      return (
        q
          .accounts({
            where: {
              id: { _eq: args.accountId },
              tenantId: { _eq: args.teamId },
            },
            limit: 1,
          })
          .map<SelectItem>((x) => ({
            label: x.title!,
            value: x.id,
          }))[0] || null
      );
    },
    {
      skip: !teamId,
      queryKey: ['accounts'],
      keepPreviousData: true,
      variables: {
        accountId: filter.accountId,
        teamId,
      },
    }
  );

  return (
    <Filter.Select
      value={data || null}
      setValue={(v) => setFilter({ accountId: v?.value })}
      type="single"
      label="Bank Account"
      withinPortal
      infiniteData={{ ...queryData, setSearch }}
      customBottomActions={[
        {
          label: 'Non-Trust Account',
          onSubmit: () => setFilter({ accountId: 'non-trust' }),
          id: 'non-trust',
        },
      ]}
    />
  );
};

const PaidStatusFilter = () => {
  const { filter, setFilter } = useExpenseTableFilter();

  const options = useMemo<SelectItem<paidStatus_enum>[]>(() => {
    return [
      {
        label: 'Paid',
        value: 'paid',
        icon: (
          <Box
            w={10}
            h={10}
            sx={(theme) => ({
              alignSelf: 'center',
              borderRadius: theme.radius.xl,
              backgroundColor: theme.colors.green[6],
            })}
          />
        ),
      },
      {
        label: 'Unpaid',
        value: 'unpaid',
        icon: (
          <Box
            w={10}
            h={10}
            sx={(theme) => ({
              alignSelf: 'center',
              borderRadius: theme.radius.xl,
              backgroundColor: theme.colors.yellow[6],
            })}
          />
        ),
      },
    ];
  }, []);

  const value = options.find((o) => o.value === filter.paidStatus) || null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ paidStatus: v?.value })}
      type="single"
      label="Status"
      withinPortal
      data={{
        options,
      }}
      hideIcon
    />
  );
};
