import {
  Button,
  Collapse,
  IconButton,
  Input,
  InputDay,
  InputTextarea,
  InputWrapper,
} from '@finalytic/components';
import { useQuery, useTeamId } from '@finalytic/data';
import { createUUID } from '@finalytic/data-ui';
import { paidStatus_enum } from '@finalytic/graphql';
import { Icon, type IconDefinition } from '@finalytic/icons';
import { LazyTable, type MRT_ColumnDef } from '@finalytic/table';
import { day, ensure, formatCurrency, sum } from '@finalytic/utils';
import {
  Box,
  Center,
  Checkbox,
  Group,
  LoadingOverlay,
  SegmentedControl,
  Stack,
  Title,
  Tooltip,
  rem,
} from '@mantine/core';
import { Text } from '@mantine/core';
import { Collapse as MantineCollapse } from '@mantine/core';
import { type ReactNode, useCallback, useMemo } from 'react';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import { MissingAccountAssignmentsOverlay } from '../../../components';
import { getMissingAccountAssignments } from '../../../queries';
import {
  UnreconciledBankRecord,
  UnreconciledBankRecordCard,
} from '../../reconciliation/bank-accounts/view/UnreconciledBankRecordCard';
import {
  ExpenseAmountInput,
  ExpenseBankAccountInput,
  ExpenseContactInput,
  ExpenseCurrencyInput,
  ExpenseLineAccount,
  ExpenseListingReservationSelect,
} from './_components';
import { type ExpenseFormInputs, useExpenseForm } from './useExpenseForm';

type Props = {
  onSubmit: (values: ExpenseFormInputs) => void;
  onReset: () => void;
  loading: boolean;
  bankRecord?: UnreconciledBankRecord;
};

function useMissingAssignmentsQuery() {
  const [teamId] = useTeamId();

  const queryData = useQuery(
    (q, args) => {
      return getMissingAccountAssignments(q, {
        teamId: args.teamId,
        whereAccountAssigments: {
          name: { _ilike: 'expense%' },
        },
      });
    },
    {
      variables: {
        teamId: teamId,
      },
      keepPreviousData: true,
      queryKey: ['accounts'],
    }
  );

  return queryData;
}

export const EXPENSE_FORM_ID = 'expense-form';

export const ExpenseForm = ({
  loading: loadingValues,
  bankRecord,
  onReset,
  onSubmit,
}: Props) => {
  const missingAssignmentsQueryData = useMissingAssignmentsQuery();
  const isLoading = loadingValues || missingAssignmentsQueryData.isLoading;

  const methods = useExpenseForm();

  return (
    <Box
      component="form"
      onReset={onReset}
      onSubmit={methods.handleSubmit(onSubmit)}
      pos="relative"
      name="expense-form"
      id={EXPENSE_FORM_ID}
    >
      <LoadingOverlay visible={isLoading} />
      <MissingAccountAssignmentsOverlay
        isLoading={isLoading}
        accountAssignments={missingAssignmentsQueryData.data}
      />

      {bankRecord ? (
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'stretch',
            gap: theme.spacing.md,
            alignItems: 'flex-start',
          })}
        >
          <Box
            sx={{
              flex: 1,
            }}
          >
            <GeneralInputs bankRecord={bankRecord} />
          </Box>
          <Box
            sx={() => ({
              minWidth: 400,
              flex: 0.5,
            })}
          >
            <UnreconciledBankRecordCard bankRecord={bankRecord} />
          </Box>
        </Box>
      ) : (
        <GeneralInputs bankRecord={bankRecord} />
      )}

      {/* Lines */}
      <Lines />
    </Box>
  );
};

const GeneralInputs = ({ bankRecord }: { bankRecord: Props['bankRecord'] }) => {
  const methods = useExpenseForm();
  const paidStatus = methods.watch('paidStatus');

  return (
    <Collapse
      title="Expense"
      defaultOpened
      contentSx={(theme) => ({
        marginBottom: theme.spacing.xl,
      })}
    >
      <Stack px="sm" gap="md">
        {!bankRecord && (
          <InputContainer icon="LoaderIcon" title="Status">
            <Controller
              control={methods.control}
              name="paidStatus"
              rules={{
                required: 'Status is required',
              }}
              render={({
                field: { value, onBlur, onChange, disabled },
                fieldState,
              }) => {
                return (
                  <InputWrapper error={fieldState.error?.message}>
                    <SegmentedControl
                      size="sm"
                      disabled={disabled}
                      w={400}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      data={ensure<{ label: string; value: paidStatus_enum }[]>(
                        [
                          {
                            label: 'Paid',
                            value: 'paid',
                          },
                          {
                            label: 'Unpaid',
                            value: 'unpaid',
                          },
                        ]
                      )}
                    />
                  </InputWrapper>
                );
              }}
            />
          </InputContainer>
        )}

        <InputContainer icon="CalendarEventIcon" title="Issue Date">
          <Controller
            control={methods.control}
            name="date"
            rules={{
              required: 'Date is required',
            }}
            render={({ field: { value, onBlur, onChange }, fieldState }) => {
              const v = value ? day(value).toDate() : null;

              return (
                <InputDay
                  onChange={(x) => {
                    onChange(x);
                    const paidAt = methods.getValues('paidAt');
                    if (!paidAt && x) {
                      methods.setValue('paidAt', day(x).yyyymmdd());
                      methods.clearErrors('paidAt');
                    }
                  }}
                  onBlur={onBlur}
                  required
                  value={v}
                  error={fieldState.error?.message}
                  placeholder={day().format('MMMM DD, YYYY')}
                  clearable
                  popoverProps={{
                    position: 'bottom-end',
                    withinPortal: true,
                  }}
                />
              );
            }}
          />
        </InputContainer>

        <MantineCollapse in={paidStatus === 'paid'}>
          <InputContainer icon="CalendarEventIcon" title="Payment Date">
            <Controller
              control={methods.control}
              name="paidAt"
              rules={{
                required:
                  paidStatus === 'paid' ? 'Payment Date is required' : false,
              }}
              render={({ field: { value, onBlur, onChange }, fieldState }) => {
                const v = value ? day(value).toDate() : null;

                return (
                  <InputDay
                    onChange={onChange}
                    onBlur={onBlur}
                    required
                    value={v}
                    error={fieldState.error?.message}
                    placeholder={day().format('MMMM DD, YYYY')}
                    clearable
                    maxDate={day().toDate()}
                    popoverProps={{
                      position: 'bottom-end',
                      withinPortal: true,
                    }}
                  />
                );
              }}
            />
          </InputContainer>
        </MantineCollapse>

        <InputContainer icon="HashtagIcon" title="Bill ID" maw={500}>
          <Controller
            control={methods.control}
            name="uniqueRef"
            render={({ field, fieldState }) => {
              return (
                <InputWrapper error={fieldState.error?.message}>
                  <Input
                    onChange={field.onChange}
                    value={field.value}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    placeholder={'124121'}
                    width={300}
                  />
                </InputWrapper>
              );
            }}
          />
        </InputContainer>

        <InputContainer icon="CircleDollarIcon" title="Currency">
          <ExpenseCurrencyInput />
        </InputContainer>

        <MantineCollapse in={paidStatus === 'paid'}>
          <InputContainer
            icon="FolderOpenIcon"
            title="Bank/CC account"
            maw={400}
          >
            <ExpenseBankAccountInput required={paidStatus === 'paid'} />
          </InputContainer>
        </MantineCollapse>

        <InputContainer icon="UserPlusIcon" title="Contact/Vendor" maw={400}>
          <ExpenseContactInput />
        </InputContainer>

        <InputContainer icon="NoteTextIcon" title="Description" maw={500}>
          <Controller
            control={methods.control}
            name="description"
            rules={{
              validate: (value) =>
                value?.trim().length > 0 || 'Description is required',
            }}
            render={({ field, fieldState }) => {
              return (
                <InputWrapper error={fieldState.error?.message}>
                  <InputTextarea
                    onChange={field.onChange}
                    value={field.value}
                    onBlur={field.onBlur}
                    height={100}
                    error={!!fieldState.error}
                    placeholder={'Pool cleaning service'}
                  />
                </InputWrapper>
              );
            }}
          />
        </InputContainer>
      </Stack>
    </Collapse>
  );
};

const InputContainer = ({
  children,
  icon,
  title,
  maw = 300,
}: {
  icon: IconDefinition;
  title: string;
  children?: ReactNode;
  maw?: number;
}) => {
  return (
    <Group align="flex-start" w="100%">
      <Group pt={8}>
        <Icon icon={icon} size={18} />
        <Text fw={500} miw={150} ta="left">
          {title}
        </Text>
      </Group>
      <Box
        sx={{
          flex: 1,
        }}
        maw={maw}
      >
        {children}
      </Box>
    </Group>
  );
};

const Lines = () => {
  const methods = useExpenseForm();

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'lines',
    rules: {
      validate: (value) => {
        if (!value || value.length === 0) return 'Please add an expense line';
        return true;
      },
    },
  });

  const columns = useMemo<MRT_ColumnDef<ExpenseFormInputs['lines'][0]>[]>(
    () => [
      {
        header: 'Listing/Reservation',
        accessorKey: 'listingId',
        maxSize: 0,
        size: 300,
        minSize: 300,
        mantineTableBodyCellProps: {
          sx: {
            alignItems: 'flex-start',
          },
        },
        Cell: ({ row }) => (
          <ExpenseListingReservationSelect index={row.index} />
        ),
      },
      {
        header: 'Expense account',
        accessorKey: 'accountId',
        maxSize: 0,
        size: 300,
        minSize: 300,
        mantineTableBodyCellProps: {
          sx: {
            alignItems: 'flex-start',
          },
        },
        Cell: ({ row }) => {
          return <ExpenseLineAccount index={row.index} />;
        },
      },
      {
        header: 'Description',
        accessorKey: 'description',
        mantineTableBodyCellProps: {
          sx: {
            alignItems: 'flex-start',
          },
        },
        grow: true,
        Cell: ({ row }) => {
          const index = row.index;

          return (
            <Controller
              control={methods.control}
              name={`lines.${index}.description`}
              rules={{
                validate: (value) =>
                  value?.trim().length > 0 || 'Description is required',
              }}
              render={({ field, fieldState }) => {
                return (
                  <Input
                    {...field}
                    width={'100%'}
                    error={!!fieldState.error}
                    placeholder={'Pool cleaning service'}
                  />
                );
              }}
            />
          );
        },
      },
      {
        header: 'Party',
        accessorKey: 'party',
        maxSize: 0,
        size: 150,
        minSize: 150,
        footer: 'Total:',
        Header: () => (
          <Group justify="center" wrap="nowrap" gap={8} w="100%">
            <Text span inherit>
              Bill to owner
            </Text>
            <Tooltip
              label="When selecting Bill to Owner, the transaction will appear on the owner statement. If left unchecked, the expense will default to the Property Manager Statement."
              withArrow
              multiline
              maw={300}
            >
              <Center>
                <Icon icon="InfoIcon" size={16} />
              </Center>
            </Tooltip>
          </Group>
        ),
        mantineTableHeadCellProps: {
          align: 'center',
          sx: (theme) => ({
            '.mrt-table-head-cell-labels': {
              paddingLeft: 0,
            },
            fontWeight: 400,
            color: theme.colors.gray[7],
          }),
        },
        mantineTableBodyCellProps: {
          align: 'center',
          sx: {
            alignItems: 'flex-start',
            paddingTop: 18,
          },
        },
        mantineTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          const index = row.index;

          const paidStatus = useWatch({
            control: methods.control,
            name: 'paidStatus',
          });

          const isNonTrustAccount =
            useWatch({
              control: methods.control,
              name: 'accountId',
            }) === 'non-trust';

          const forceBillToOwner = isNonTrustAccount && paidStatus === 'paid';

          return (
            <Controller
              control={methods.control}
              name={`lines.${index}.party`}
              defaultValue="manager"
              // disabled={forceBillToOwner}
              rules={{
                validate: (value) => {
                  if (!value) return 'Please select a party';

                  if (forceBillToOwner && value !== 'owners') {
                    return 'Bill to owner needs to be enabled when selecting non-trust account';
                  }

                  return true;
                },
              }}
              render={({ field, fieldState }) => {
                // useEffect(() => {
                //   if (forceBillToOwner) field.onChange('owners');
                // }, [forceBillToOwner, field.onChange]);

                const error = fieldState.error?.message;

                return (
                  <Tooltip withArrow label={error} disabled={!error}>
                    <Center
                      sx={{
                        gap: 5,
                      }}
                    >
                      {error && (
                        <Icon
                          icon="InfoIcon"
                          size={16}
                          color={(theme) => theme.colors.red[6]}
                        />
                      )}
                      <Checkbox
                        checked={field.value === 'owners'}
                        error={!!error}
                        disabled={field.disabled}
                        onChange={(event) =>
                          field.onChange(
                            event.currentTarget.checked ? 'owners' : 'manager'
                          )
                        }
                      />
                    </Center>
                  </Tooltip>
                );
              }}
            />
          );
        },
      },
      {
        header: 'Amount',
        accessorKey: 'centTotal',
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
          sx: {
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
          },
        },
        Footer: () => <AmountFooter />,
        maxSize: 0,
        size: 130,
        minSize: 130,
        Cell: ({ row }) => {
          const index = row.index;

          return (
            <Box
              maw="100%"
              sx={{
                // to wrap error message
                p: {
                  textWrap: 'wrap',
                  wordBreak: 'break-word',
                },
              }}
            >
              <ExpenseAmountInput index={index} />

              {/* <Controller
                control={methods.control}
                name={`lines.${index}.centTotal`}
                rules={{
                  validate: (value) => {
                    if (typeof value !== 'number') return 'Amount is required';
                    if (value <= 0) return 'Please enter a positive amount';
                    return true;
                  },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <InputAmount
                      w="100%"
                      value={Number(field.value || 0) / 100}
                      setValue={(value) =>
                        field.onChange(Number(value || 0) * 100)
                      }
                      error={fieldState.error?.message}
                      placeholder={'$0.00'}
                      decimalScale={2}
                      hideControls
                    />
                  );
                }}
              /> */}
            </Box>
          );
        },
      },
      {
        header: 'Delete',
        id: 'delete',
        Header: '',
        maxSize: 0,
        size: 50,
        minSize: 50,
        Cell: ({ row }) => {
          return (
            <Center
              sx={{
                alignItems: 'flex-start',
                paddingTop: 4,
                alignSelf: 'flex-start',
              }}
            >
              <IconButton
                icon="TrashIcon"
                size={18}
                onClick={() => remove(row.index)}
              />
            </Center>
          );
        },
      },
    ],
    [remove, methods.control]
  );

  const addLine = useCallback(
    () =>
      append({
        id: createUUID(),
        description: '',
        centTotal: 0,
        accountId: null,
        listingId: null,
        reservationId: null,
        rateBehavior: 'included',
        rateId: null,
        party: 'owners',
        markup: undefined,
        // markupType: 'rate',
        // markupId: null,
        // markupTaxRateId: null,
        // markupTaxBehavior: null,
      }),
    [append]
  );

  const sectionErrorMessage = methods.formState.errors.lines?.root?.message;

  return (
    <Collapse
      title="Expense lines"
      defaultOpened
      rightSection={
        <Button
          type="button"
          variant="light"
          onClick={addLine}
          leftIcon="PlusIcon"
        >
          Add expense line
        </Button>
      }
    >
      <Tooltip
        withArrow
        label={sectionErrorMessage}
        disabled={!sectionErrorMessage}
      >
        <Box
          sx={(theme) => ({
            '.mantine-Table-tfoot': {
              display: !fields.length ? 'none' : undefined,
            },
            border: sectionErrorMessage
              ? `1px solid ${theme.colors.red[6]}`
              : undefined,
            borderRadius: sectionErrorMessage ? theme.radius.md : undefined,
            overflow: sectionErrorMessage ? 'hidden' : undefined,
            paddingBottom: sectionErrorMessage ? theme.spacing.xs : undefined,
          })}
        >
          <LazyTable
            table={{
              columns,
              hideSettings: true,
              emptyRowsFallback: () => (
                <Stack
                  maw={400}
                  mx="auto"
                  align="center"
                  mb={rem(50)}
                  gap="lg"
                  mt={rem(60)}
                >
                  <Title order={4} fw={500} c="neutral">
                    Add your first expense line
                  </Title>
                  <Text c="neutral" ta="center">
                    Start by addind your first line that make up your expense.
                    <br />A line can be related to a reservation or a listing.
                  </Text>
                  <Button
                    type="button"
                    variant="primary"
                    onClick={addLine}
                    leftIcon="PlusIcon"
                    size="xs"
                  >
                    Add expense line
                  </Button>
                </Stack>
              ),
            }}
            data={{
              rows: fields,
              rowCount: fields.length,
              error: null,
              loading: false,
            }}
          />
        </Box>
      </Tooltip>
    </Collapse>
  );
};

const AmountFooter = () => {
  const { control } = useExpenseForm();
  const lines = useWatch({
    control,
    name: 'lines',
  });

  const currency = useWatch({
    control,
    name: 'currency',
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const total = useMemo(() => {
    return sum(
      lines?.map((line) => (line.centTotal || 0) + (line.markup || 0))
    );
  }, [lines?.map((x) => x.centTotal)]);

  return (
    <Text ta="right" fw={'bold'} size="xs">
      {formatCurrency(total / 100, currency)}
    </Text>
  );
};
