import { Button, Tag } from '@finalytic/components';
import { Icon } from '@finalytic/icons';
import { Group, Overlay, Text, Title } from '@mantine/core';
import { useNavigate } from 'react-router';
import { getMissingAccountAssignments } from '../queries';

type Props = {
  accountAssignments:
    | ReturnType<typeof getMissingAccountAssignments>
    | undefined
    | null;
  isLoading: boolean;
};

export const MissingAccountAssignmentsOverlay = ({
  accountAssignments,
  isLoading,
}: Props) => {
  const goto = useNavigate();

  if (!accountAssignments?.length || isLoading) return null;

  return (
    <Overlay
      color="#fff"
      backgroundOpacity={0.8}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Icon icon="AlertTriangleIcon" size={32} color="orange" />
      <Title order={3} mb="sm" mt="lg" ta="center" c="dark">
        Missing Assignments
      </Title>
      <Text size="sm" c="neutral" mb="lg" ta="center">
        Please assign accounts to continue adding or updating deposits.
      </Text>
      <Group gap="xs" maw={700} justify="center" mb="lg">
        {accountAssignments.map((assignment) => (
          <Tag key={assignment.id} shadow="sm">
            {assignment.title}
          </Tag>
        ))}
      </Group>
      <Button
        variant="primary"
        onClick={() => goto('/settings/integrations/accounts')}
        leftIcon="PlusIcon"
      >
        Add assignments
      </Button>
    </Overlay>
  );
};
