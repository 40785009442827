import {
  captureSentryError,
  useApiMutation,
  useGqtyClient,
  useInvalidateQueries,
} from '@finalytic/data';
import { showErrorNotification } from '@finalytic/ui';
import { day, hasValue } from '@finalytic/utils';
import { useCallback, useEffect } from 'react';
import { type ExpenseFormInputs } from './useExpenseForm';

export function useExpenseFormMutation({
  bankRecordId,
}: { bankRecordId: string | undefined }) {
  const invalidate = useInvalidateQueries(['expenses', 'ownerStatements']);

  const client = useGqtyClient();

  const {
    mutateAsync: insert,
    isPending: loadingInsert,
    error: errorInsert,
  } = useApiMutation('post', '/transactions', {
    onSettled: () => {
      invalidate();
    },
  });
  const {
    mutateAsync: update,
    isPending: loadingUpdate,
    error: errorUpdate,
  } = useApiMutation('put', '/transactions/{id}', {
    onSettled: () => {
      invalidate();
    },
  });

  const mutate = useCallback(
    async (input: ExpenseFormInputs) => {
      type RequestBody = Parameters<typeof insert>[0]['body'];

      const reservations = await client.query((q) => {
        return q
          .reservations({
            where: {
              id: {
                _in: input.lines.map((x) => x.reservationId).filter(hasValue),
              },
            },
          })
          .map((reservation) => ({
            id: reservation.id as string,
            listingId: reservation.listingId as string,
          }));
      });

      const isPaid = input.paidStatus === 'paid' || bankRecordId;

      const requestBody: RequestBody = {
        accountId: !isPaid
          ? (null as any)
          : input.accountId === 'non-trust'
            ? (null as any)
            : input.accountId,
        currency: input.currency,
        date: day(input.date).yyyymmdd(),
        contactId: input.contactId as any,
        description: input.description?.trim(),
        uniqueRef: input.uniqueRef?.trim(),
        type: 'expense',
        payment: {
          status: isPaid ? 'paid' : input.paidStatus,
          date: isPaid ? day(input.paidAt).yyyymmdd() : (null as any),
          bankRecordIds: bankRecordId ? [bankRecordId] : [],
        },
        lines: input.lines.map((line) => {
          const additions: RequestBody['lines'][number]['additions'] = [];

          if (line.markup && line.party === 'owners') {
            additions.push({
              amount: line.markup,
              type: 'markup',
            });
          }

          if (line.rateId) {
            additions.push({
              rateId: line.rateId,
              behavior: line.rateBehavior || 'included',
              type: 'tax',
            });
          }

          const listingId =
            line.listingId ||
            reservations.find((x) => x.id === line.reservationId)?.listingId;

          if (!listingId) throw new Error('Listing not found');

          return {
            accountId: line.accountId!,
            amount: line.centTotal,
            description: line.description?.trim(),
            reservationId: line.reservationId || undefined,
            party: line.party,
            listingId,
            additions,
          };
        }),
      };

      if (input.id) {
        await update({
          params: {
            path: {
              id: input.id,
            },
          },
          body: requestBody,
        });
      } else {
        await insert({
          body: requestBody,
        });
      }
    },
    [insert, update, client]
  );

  const error = errorInsert || errorUpdate;

  useEffect(() => {
    const err = error as any;

    if (err) {
      console.error(err);
      captureSentryError(err);
      showErrorNotification({
        title: "Couldn't save expense",
        message:
          err?.body?.message ||
          err?.message ||
          "We couldn't save the expense. Please try again.",
        autoClose: 5000,
      });
    }
  }, [error]);

  return {
    loading: loadingInsert || loadingUpdate,
    mutate,
  };
}
